import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { hideHeader } from "../../redux/userActions";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import SignUpForm from "../../components/SignUpForm";

import "./SignUp.scss";

const SignUp = ({ history }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    return (
        <div className="signUp">
            <HeaderToggle />
            <Overlay />
            <Header />
            <SignUpForm history={history} />
        </div>
    );
};

export default SignUp;
