import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { hideHeader } from "../../redux/userActions";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import LoginForm from "../../components/LoginForm";
import PricePlan from "../../components/PricePlan";

import "./Login.scss";
import { trialPeriod } from "../../helpers/variables";

const Login = ({ history }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [message, setMessage] = useState("");

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    useEffect(() => {
        if (user.role) {
            setMessage("Usted ya tiene una sesión iniciada.");
            switch (user.role) {
                case "pending":
                    setMessage(
                        "Usted ya tiene una sesión iniciada. Pendiente validar email"
                    );
                    break;
                case "standard":
                    setMessage(
                        "Usted ya tiene una sesión iniciada. Puede convertirse en miembro:"
                    );
                    break;
                case "member":
                    setMessage("Usted ya tiene una sesión iniciada como miembro");
                    break;
                case "photo-editor":
                    setMessage("Usted ya tiene una sesión iniciada como Photo Editor");
                    break;
                case "admin":
                    setMessage("Usted ya tiene una sesión iniciada como ADMIN");
                    break;
                default:
                    setMessage("");
            }
        } else {
            setMessage("Usted no tiene una sesión iniciada.");
        }
    }, [user]);

    return (
        <div className="login">
            <HeaderToggle />
            <Overlay />
            <Header />
            {!user.role && <LoginForm history={history} />}
            <span className="message">{message}</span>
            {user.role === "member" &&
            user.createdOn + trialPeriod > Date.now() &&
            user.trialPeriod === true ? (
                <span className="message">
                    Usted está en disfrutando del periodo de prueba gratuito.
                </span>
            ) : (
                <></>
            )}
            {user.role === "standard" &&
            user.createdOn + trialPeriod < Date.now() &&
            user.trialPeriod === false ? (
                <span className="message">Su periodo de prueba ha vencido.</span>
            ) : (
                <></>
            )}
            {!user.role && (
                <Link to={"./signup"} className="createAccountLink">
                    Crear cuenta
                </Link>
            )}
            {!user.role && (
                <Link to={"./recoverPassword"} className="createAccountLink">
                    He olvidado mi contraseña.
                </Link>
            )}
            <div className="spacer"></div>
            <PricePlan />
        </div>
    );
};

export default Login;
