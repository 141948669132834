import React from "react";

import { playerBatStats } from "../../../helpers/validation";
import {
    totalizePlayerArray,
    removeFromArray,
    mapArrayIntoObject,
    getSR50StatsBatter,
} from "../../../helpers/structureData";

import TableHeader from "../TableHeader";
import TableCell from "../TableCell";
import TableRow from "../TableRow";
import Anchor from "../../Anchor";

import "./PlayerBat.scss";

const PlayerBat = ({ batData, reorder, orderedBy, yearMetrics }) => {
    const stats = Object.keys(playerBatStats);
    const totalStats = removeFromArray(stats, ["teamFullDesc", "age", "etapaDesc"]);
    const { SR, playoffs } = batData;

    const SRTotals = SR.length
        ? totalizePlayerArray(
              SR,
              Object.keys(playerBatStats),
              ["age"],
              "bat",
              yearMetrics
          )
        : undefined;
    const playoffsTotals = playoffs.length
        ? totalizePlayerArray(
              playoffs,
              Object.keys(playerBatStats),
              ["age"],
              "bat",
              yearMetrics
          )
        : undefined;
    const allTotals = totalizePlayerArray(
        [...SR, ...playoffs],
        Object.keys(playerBatStats),
        ["age"],
        "bat",
        yearMetrics
    );
    const SR50Stats = SRTotals === undefined ? undefined : getSR50StatsBatter(SRTotals);

    const injectAnchor = (obj) => {
        const team = obj["teamDesc"];
        const phase = obj["etapaDesc"];
        const year = obj["seasonCode"];
        const teamName = obj["teamFullDesc"];
        const anchor = (
            <Anchor
                value={teamName}
                href={`teams?year=${year}&phase=${phase}&team=${team}`}
            ></Anchor>
        );
        return { ...obj, teamFullDesc: anchor };
    };

    return (
        <div className="playerBat">
            {SR.length ? (
                <>
                    <h3>Bateador - Serie Regular</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerBatStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                                origin="BatSR"
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {batData["SR"].map((player, index) => {
                                    const modPlayer = injectAnchor(player);
                                    return (
                                        <TableRow key={index} y={index}>
                                            {stats.map((field, index) => {
                                                return (
                                                    <TableCell
                                                        key={field + index}
                                                        value={modPlayer[field]}
                                                        x={`x${index}`}
                                                        header={field}
                                                        orderedBy={orderedBy["BatSR"]}
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                <tr>
                                    {SR50Stats
                                        ? stats.map((field, index) => {
                                              return (
                                                  <TableCell
                                                      key={field + index}
                                                      value={SRTotals[field]}
                                                      x={`x${index}`}
                                                      amTotal={true}
                                                  />
                                              );
                                          })
                                        : null}
                                </tr>
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={SR50Stats[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                                className="special"
                                            />
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div></div>
            )}
            {playoffs.length ? (
                <>
                    <h3>Bateador - Playoffs</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerBatStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                                origin="BatPlayoffs"
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {batData["playoffs"].map((player, index) => {
                                    const modPlayer = injectAnchor(player);
                                    return (
                                        <TableRow key={index} y={index}>
                                            {stats.map((field, index) => {
                                                return (
                                                    <TableCell
                                                        key={field + index}
                                                        value={modPlayer[field]}
                                                        x={`x${index}`}
                                                        header={field}
                                                        orderedBy={
                                                            orderedBy["BatPlayoffs"]
                                                        }
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={playoffsTotals[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                            />
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {mapArrayIntoObject(playoffs, "etapaDesc")["groups"].length > 0 && (
                        <div className="tableContainer">
                            <h5>Total Playoffs</h5>
                            <table>
                                <thead>
                                    <tr>
                                        {totalStats.map((field, index) => {
                                            return (
                                                <TableHeader
                                                    key={field}
                                                    value={playerBatStats[field]}
                                                    x={`x${index}`}
                                                    reorder={reorder}
                                                    field={field}
                                                />
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapArrayIntoObject(playoffs, "etapaDesc")[
                                        "groups"
                                    ].map((phase, index) => {
                                        return (
                                            <TableRow key={index} y={index}>
                                                {totalStats.map((field, index) => {
                                                    return (
                                                        <TableCell
                                                            key={field + index}
                                                            value={
                                                                totalizePlayerArray(
                                                                    mapArrayIntoObject(
                                                                        playoffs,
                                                                        "etapaDesc"
                                                                    )["obj"][phase],
                                                                    Object.keys(
                                                                        playerBatStats
                                                                    ),
                                                                    [],
                                                                    "bat",
                                                                    yearMetrics,
                                                                    `Total ${phase}`
                                                                )[field]
                                                            }
                                                            x={`x${index}`}
                                                            header={field}
                                                        />
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                    <tr>
                                        {totalStats.map((field, index) => {
                                            return (
                                                <TableCell
                                                    key={field + index}
                                                    value={
                                                        totalizePlayerArray(
                                                            playoffs,
                                                            Object.keys(playerBatStats),
                                                            ["age"],
                                                            "bat",
                                                            yearMetrics
                                                        )[field]
                                                    }
                                                    x={`x${index}`}
                                                    amTotal={true}
                                                />
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            ) : (
                <div></div>
            )}
            {SR.length && playoffs.length ? ( //for grand totals of both playoffs and SR
                <>
                    <h3>Gran Total Bateador</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {totalStats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerBatStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {totalStats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={allTotals[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                            />
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default PlayerBat;
