function setUser(user) {
    return { type: "SET_USER", payload: user };
}

const showHeader = () => {
    return { type: "show" };
};

const hideHeader = () => {
    return { type: "hide" };
};

const setYearValidation = (years) => {
    return { type: "addYears", payload: years };
};

const setPlayersValidation = (players) => {
    return { type: "addPlayers", payload: players };
};

const setYearMetrics = (metrics) => {
    return { type: "setYearMetrics", payload: metrics };
};

export {
    setUser,
    showHeader,
    hideHeader,
    setYearValidation,
    setPlayersValidation,
    setYearMetrics,
};
