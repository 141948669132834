import React, { useState } from "react";

import { getDocuments, getItem, getCollectionGroup } from "../../services/databaseRead";
import { getStoragePlayersList, getFile } from "../../services/storage";

import FormInput from "../FormInput";

import "./Read.scss";

const Read = () => {
    const [singlePlayerID, setSinglePlayerID] = useState("");
    const [tokenFileProgress, setTokenFileProgress] = useState("pending");

    const handleGetBlock = async () => {
        const result = await getDocuments(
            "stats",
            { field: "seasonDesc", condition: "==", value: "1998-99" },
            { field: "etapaDesc", condition: "==", value: "SM" }
        );
        console.log(result);
    };

    const handleGetOnePlayer = async (event) => {
        event.preventDefault();
        if (singlePlayerID) {
            const result = await getItem("players", singlePlayerID);
            if (result) {
                console.log(result);
            }
        }
    };

    const handleGet = async (event) => {
        event.preventDefault();
        const result = await getItem("globalStats", "21");
        if (result) {
            console.log("RETRIEVED ITEM");
            console.log(result);
        }
    };

    const handleGetCollectionGroup = async (event) => {
        event.preventDefault();
        const result = await getCollectionGroup("SF");
        if (result) {
            console.log("RETRIEVED ITEM");
            console.log(result);
        }
    };

    const handleDownloadPlayersTokens = async () => {
        setTokenFileProgress("in-progress");
        const refs = await getStoragePlayersList();
        const filesNames = refs.items.reduce((soFar, ref) => {
            const value = ref.fullPath.split("/")[1];
            return value !== undefined ? [...soFar, value] : soFar;
        }, []);
        const data = await Promise.all(
            filesNames.map(async (code) => {
                const result = await getFile("players", code);
                return [code, result, result.split("token=")[1]];
            })
        );
        const csvContent = "data:text/csv;charset=utf-8,";
        const processedData = data.map((item) => item.join(", "));
        const csv = csvContent + processedData.join("\r\n");
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "playersData.csv");
        document.body.appendChild(link); // Required for FF
        link.click(); 
        setTokenFileProgress("pending");
    };

    return (
        <div className="read">
            <div className="inputArea">
                <div className="get">
                    <h4>Get Season - Etapa</h4>
                    <button onClick={handleGetBlock}>Get</button>
                </div>
                <div className="get">
                    <h4>Get Player Token File</h4>
                    <button onClick={handleDownloadPlayersTokens}>Download</button>
                    <div>
                        {tokenFileProgress === "in-progress" ? "Downloading..." : null}
                    </div>
                </div>
                <div>
                    <form className="get" onSubmit={handleGetOnePlayer}>
                        <h4>Get a player with ID</h4>
                        <FormInput
                            placeholder="ID or Key"
                            value={singlePlayerID}
                            onChange={(value) => setSinglePlayerID(value)}
                        />
                        <button type="submit">Get Player</button>
                    </form>
                    <form className="get" onSubmit={handleGet}>
                        <h4>Get hardcoded</h4>
                        <button type="submit">Get</button>
                    </form>
                    <form className="get" onSubmit={handleGetCollectionGroup}>
                        <h4>Get Collection Group</h4>
                        <button type="submit">Get Collection Group</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Read;
