import firebase from "firebase/app";
import "firebase/firestore";

function parseDoc(doc) {
    return {
        id: doc.id,
        ...doc.data(),
    };
}

let db;
function getDbInstance() {
    if (!db || db._isTerminated) {
        db = firebase.firestore();
    }
    return db;
}

const getCollection = async (collection, orderBy) => {
    const db = getDbInstance();
    const results = [];
    await db
        .collection(collection)
        .orderBy(orderBy)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
                results.push({ ...doc.data(), id: doc.id });
            });
        });
    console.log(results);
    return results;
};

const getCollectionGroup = async (subCollection) => {
    const db = getDbInstance();
    const results = [];
    await db
        .collectionGroup(subCollection)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
                results.push(doc.data());
            });
        });
    return results;
};

const getFilteredCollection = async (collection, key) => {
    const db = getDbInstance();
    const results = [];
    await db
        .collection(collection)
        .where(key, "==", true)
        .get()
        .then(function (querySnapshot) {
            querySnapshot.forEach((doc) => {
                console.log(doc);
                results.push(doc.data());
            });
        });
    return results;
};

async function getItem(collection, itemId) {
    const db = getDbInstance();
    //console.log(`Getting item ${itemId} from ${collection}`);
    const document = await db.collection(collection).doc(itemId).get();
    if (document.exists) {
        return parseDoc(document);
    }
    return null;
}

async function getDocuments(collection, filters1, filters2) {
    console.log("getting documents");
    const db = getDbInstance();
    const dbCollection = db.collection(collection);
    const collectionFiltered = dbCollection
        .where(filters1.field, filters1.condition, filters1.value)
        .where(filters2.field, filters2.condition, filters2.value);
    const results = [];
    await collectionFiltered.get().then(function (querySnapshot) {
        querySnapshot.forEach((doc) => {
            results.push(doc.data());
        });
    });
    return results;
}

async function getAllRealTime({ collection, filters, order, limit, callback }) {
    console.log("getting all real time");
    const db = getDbInstance();
    const dbCollection = db.collection(collection);
    const collectionFiltered = dbCollection.where(
        filters.field,
        filters.condition,
        filters.value
    );
    const collectionOrdered = collectionFiltered.orderBy(order, "desc").limit(limit);
    const execute = (collectionData) => {
        callback(collectionData);
    };
    collectionOrdered.onSnapshot(execute);
}

export {
    getCollection,
    getFilteredCollection,
    getItem,
    getDocuments,
    getAllRealTime,
    getCollectionGroup,
};
