import React from "react";
import { useDispatch } from "react-redux";

import { showHeader } from "../../redux/userActions";

import LittleBurger from "../LittleBurger";

import "./HeaderToggle.scss";

const HeaderToggle = () => {
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(showHeader());
    };

    return (
        <div className="headerToggle">
            <LittleBurger handleToggle={handleToggle} />
        </div>
    );
};

export default HeaderToggle;
