import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import querystring from "query-string";

import { hideHeader } from "../../redux/userActions";

import { getItem } from "../../services/databaseRead";

import {
    playersSeparateStats,
    sortArrayBy,
    processPlayerObject,
} from "../../helpers/structureData";
import { ascPitchMetrics } from "../../helpers/validation";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import Notice from "../../components/Notice";
import Autocomplete from "../../components/Autocomplete";
import PlayerHero from "../../components/PlayerHero";
import PlayerPitch from "../../components/Tables/PlayerPitch";
import PlayerBat from "../../components/Tables/PlayerBat";

import "./Players.scss";

const Players = ({ history }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const players = useSelector((state) => state.validations.players);
    const yearMetrics = useSelector((state) => state.yearMetrics.metrics);

    const playerKey = querystring.parse(history.location.search)["key"];

    const [searchKey, setSearchKey] = useState("");
    const [player, setPlayer] = useState("");
    const [batStats, setBatStats] = useState({ SR: [], playoffs: [] });
    const [pitchStats, setPitchStats] = useState({ SR: [], playoffs: [] });
    const [orderedBy, setOrderedBy] = useState({
        PitchSR: "",
        PitchPlayoffs: "",
        BatSR: "",
        BatPlayoffs: "",
    });
    const [notice, setNotice] = useState("");

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    useEffect(() => {
        //sets the player based on url params.
        const getPlayer = async (key) => {
            const result = await getItem("players", key);
            if (result) {
                setPlayer(result);
                const playerStats = playersSeparateStats(result.stats);
                setBatStats(processPlayerObject(playerStats["bat"], "bat", yearMetrics));
                setPitchStats(
                    processPlayerObject(playerStats["pitch"], "pitch", yearMetrics)
                );
            } else {
                setNotice("No hay resultados.");
            }
        }; //if there is a key on the url and exists on validation.
        if (
            playerKey &&
            Object.keys(players)
                .map((player) => players[player]["key"])
                .includes(playerKey)
        ) {
            getPlayer(playerKey);
        }
    }, [playerKey, players, yearMetrics]);

    const reorder = (field, origin) => {
        switch (true) {
            case origin === "PitchSR":
                const a = sortArrayBy(
                    [...pitchStats["SR"]],
                    field,
                    ascPitchMetrics.includes(field) ? "ascending" : "descending"
                );
                setPitchStats({ ...pitchStats, SR: a });
                setOrderedBy({ ...orderedBy, PitchSR: field });
                break;
            case origin === "PitchPlayoffs":
                const b = sortArrayBy(
                    [...pitchStats["playoffs"]],
                    field,
                    ascPitchMetrics.includes(field) ? "ascending" : "descending"
                );
                setPitchStats({ ...pitchStats, playoffs: b });
                setOrderedBy({ ...orderedBy, PitchPlayoffs: field });
                break;
            case origin === "BatSR":
                const c = sortArrayBy([...batStats["SR"]], field);
                setBatStats({ ...batStats, SR: c });
                setOrderedBy({ ...orderedBy, BatSR: field });
                break;
            case origin === "BatPlayoffs":
                const d = sortArrayBy([...batStats["playoffs"]], field);
                setBatStats({ ...batStats, playoffs: d });
                setOrderedBy({ ...orderedBy, BatPlayoffs: field });
                break;
            default:
                return null;
        }
    };

    const getSearch = (string) => {
        //to be passed as props to Autocomplete...
        string && setSearchKey(players[string]["key"]);
    };

    const executeQuery = () => {
        if (
            searchKey &&
            searchKey !== playerKey &&
            Object.keys(players)
                .map((player) => players[player]["key"])
                .includes(searchKey)
        ) {
            history.push(`players?key=${searchKey}`);
        }
    };

    const handlePlayerSubmit = (event) => {
        event.preventDefault();
        executeQuery();
    };

    return (
        <div className="players">
            <HeaderToggle />
            <Overlay />
            <Header />
            {user.role === "member" ||
            user.role === "member-pro" ||
            user.role === "photo-editor" ||
            user.role === "admin" ? (
                <>
                    <form onSubmit={handlePlayerSubmit}>
                        <div>Nombre:</div>
                        <Autocomplete
                            suggestions={Object.keys(players)}
                            getSearch={getSearch}
                        />
                        <button type="submit">Buscar</button>
                    </form>
                    <div className="content">
                        {!player ? (
                            <div className="noResults">
                                No hay resultados que mostrar.
                            </div>
                        ) : (
                            <>
                                {notice && <div className="noResults">{notice}</div>}
                                <PlayerHero playerData={player} />
                                {player.pos === "P" &&
                                (pitchStats["SR"].length ||
                                    pitchStats["playoffs"].length) ? (
                                    <PlayerPitch
                                        pitchData={pitchStats}
                                        reorder={reorder}
                                        orderedBy={orderedBy}
                                        yearMetrics={yearMetrics}
                                    />
                                ) : (
                                    <></>
                                )}
                                {batStats["SR"].length || batStats["playoffs"].length ? (
                                    <PlayerBat
                                        batData={batStats}
                                        reorder={reorder}
                                        orderedBy={orderedBy}
                                        yearMetrics={yearMetrics}
                                    />
                                ) : (
                                    <></>
                                )}
                                {player.pos !== "P" &&
                                (pitchStats["SR"].length ||
                                    pitchStats["playoffs"].length) ? (
                                    <PlayerPitch
                                        pitchData={pitchStats}
                                        reorder={reorder}
                                        orderedBy={orderedBy}
                                        yearMetrics={yearMetrics}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <Notice requiredMemebership="suscriptor" />
            )}
        </div>
    );
};

export default Players;
