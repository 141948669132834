import React, { useState, useEffect } from "react";

import { getFile } from "../../services/storage";

import { teams } from "../../helpers/validation";

import "./TeamHero.scss";

const TeamHero = ({ globalData }) => {
    const {
        managers,
        founded,
        stadium,
        tournamentList,
        teamlogo,
        record,
        place,
    } = globalData;
    const teamName = Object.entries(teams).filter(
        (entry) => entry[1] === globalData.team
    )[0][0];
    const phase = globalData.phase;
    const year = globalData.year;

    const [teamPic, setTeamPic] = useState("");

    useEffect(() => {
        const getTeamPic = async () => {
            const result = await getFile("teamLogos", `${teamlogo}.jpeg`);
            result && setTeamPic(result);
        };
        getTeamPic();
    }, [teamlogo]);

    return (
        <div className="teamHero">
            <div className="teamHeroContent">
                <h2>{`${teamName} - ${phase} - ${year}`}</h2>
                <div>
                    <img src={teamPic} alt={`${teamName} logo`} />
                    <div>
                        <p>Record: {`${record} (${place})`}</p>
                        <p>Managers: {managers}</p>
                        <p>Plantilla: {founded}</p>
                        <p>Estadio: {stadium}</p>
                        {tournamentList.length > 0 && (
                            <p>
                                {`${tournamentList.split("/").length} `}Coronas:{" "}
                                {tournamentList}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="extra"></div>
        </div>
    );
};

export default TeamHero;
