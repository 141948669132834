import React, { useState } from "react";

import { signup } from "../../services/auth";

import FormInput from "../FormInput";

import "./SignUpForm.scss";

const SignUpForm = ({ history }) => {
    const [signUpData, setSignUpData] = useState({ email: "", password: "" });
    const [message, setMessage] = useState("");

    const handleSignUp = (event) => {
        event.preventDefault();
        setMessage("");

        const { email, password } = signUpData;

        if (!email || !password) {
            setMessage("Por favor, rellene todos los campos.");
        } else {
            signup(email, password);
            setMessage("Email de verificación enviado.");
        }
    };
    return (
        <form onSubmit={handleSignUp} className="signUpForm">
            <h3>Crear cuenta</h3>
            <FormInput
                placeholder="Email"
                value={signUpData.email}
                type="email"
                onChange={(value) => setSignUpData({ ...signUpData, email: value })}
            />
            <FormInput
                placeholder="Contraseña"
                value={signUpData.password}
                type="password"
                onChange={(value) => setSignUpData({ ...signUpData, password: value })}
            />
            <button type="submit">Crear</button>
            <span className="message">{message}</span>
        </form>
    );
};

export default SignUpForm;
