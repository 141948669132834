import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { hideHeader } from "../../redux/userActions";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import PricePlan from "../../components/PricePlan";
import RoleNotice from "../../components/RoleNotice";

import "./Home.scss";
import logo from "../../images/mainLogo.png";
import beisbol from "../../images/beisboldominicano.jpg";
import report from "../../images/report.JPG";
import jugadores from "../../images/jugadores.jpg";
import baseball from "../../images/baseballSmall.png";

const Home = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    return (
        <div className="home">
            <HeaderToggle />
            <Overlay />
            <Header />
            <div className="content">
                <div className="homeHero">
                    <h3>Bienvenidos a</h3>
                    <img src={logo} alt="winterballdata logo" />
                    <p className="subtitle">
                        Estadísticas históricas del béisbol Dominicano.
                    </p>
                    {user.role === "pending" && (
                        <p>Por favor, verifique su correo electrónico.</p>
                    )}
                    <div className="homeLinks">
                        {!user.role && <Link to="/login">Acceda a su cuenta</Link>}
                        {user.role && user.role !== "pending" && (
                            <Link to="/teams">Reporte de Equipos</Link>
                        )}
                        {user.role && user.role !== "pending" && (
                            <Link to="/players">Reporte de Jugadores</Link>
                        )}
                    </div>
                </div>
                <RoleNotice user={user} />
                <div className="infoBlock">
                    <div>
                        <h4>Historia</h4>
                        <p>
                            Primera web con los datos históricos del béisbol profesional
                            dominicano desde su fundación en 1951 hasta la actualidad.
                        </p>
                    </div>
                    <img src={beisbol} alt="beisbol" />
                </div>
                <div className="spacer">
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                </div>
                <div className="infoBlock middleBlock">
                    <div>
                        <h4>Jugadores</h4>
                        <p>
                            Datos acumulados por torneo , etapa y equipo de cada uno de
                            los más 6000 jugadores nativos e importados que han visto
                            acción en la liga.
                        </p>
                        <p>
                            Datos biográficos, lideratos, premios, transacciones,
                            imágenes, misceláneos y más
                        </p>
                    </div>
                    <img src={jugadores} alt="jugadores" />
                </div>
                <div className="spacer">
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                </div>
                <div className="infoBlock">
                    <div>
                        <h4>Equipos</h4>
                        <p>
                            Datos totales por equipo y etapa de cada una de los torneos
                            efectuados desde 1951
                        </p>
                        <p>
                            Información adicional, logos, records, managers, campeonatos y
                            totales
                        </p>
                    </div>
                    <img src={report} alt="report" />
                </div>
                <div className="spacer">
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                    <img src={baseball} alt="baseball" />
                </div>
                <div className="spacer">
                    <h4>Precios</h4>
                </div>
                <PricePlan />
                <div className="infoBlock">
                    <p>
                        Toda la información estadística de equipos y jugadores contenida
                        en este sitio web proviene de fuentes no oficiales y externas a la
                        liga, fruto de la colaboración de varios analistas e historiadores
                        dominicanos.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;
