import React from "react";

import "./TableHeader.scss";

const TableHeader = ({ value, x, field, reorder, origin }) => {
    const handleReorder = () => {
        reorder(field, origin);
    };

    return (
        <th className={`tableHeader ${x}`} onClick={handleReorder}>
            {value}
        </th>
    );
};

export default TableHeader;
