import React from "react";

import "./Docs.scss";

const Docs = () => {
    return (
        <div className="docs">
            <div className="docsBlock">
                <h4>Downloading players images</h4>
                <ul style={{ paddingLeft: "20px" }}>
                    <li>Need: gcloud, gsutil</li>
                    <li>Open folder and navigate on terminal</li>
                    <li>Run command</li>
                    <pre>gsutil -m cp -r gs://wbd-visualizaer.appspot.com/players . </pre>
                </ul>
                <h4>Links</h4>
                <a
                    href={
                        "https://console.cloud.google.com/storage/browser/wbd-visualizaer.appspot.com"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Cloud Buckets
                </a>
            </div>
        </div>
    );
};

export default Docs;
