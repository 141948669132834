export default {
    firebase: {
        apiKey: "AIzaSyBfT4LcMCgfG9r0oM1l2qX2Gz8RY8ATbFw",
        authDomain: "wbd-visualizaer.firebaseapp.com",
        databaseURL: "https://wbd-visualizaer.firebaseio.com",
        projectId: "wbd-visualizaer",
        storageBucket: "wbd-visualizaer.appspot.com",
        messagingSenderId: "894775092495",
        appId: "1:894775092495:web:eb4ad8ed1470d42ebeca7a",
        measurementId: "G-KM8HGWP5T6",
    },
};
