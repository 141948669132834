import React from "react";
import { Link } from "react-router-dom";

import { trialPeriod, basePayDate, year, day } from "../../helpers/variables";

import "./RoleNotice.scss";

const getDays = (paydate) => {
    return Math.ceil((paydate + year - Date.now()) / day);
};

const RoleNotice = ({ user }) => {
    return (
        <div className="roleNotice">
            {user.role === "member" &&
            user.createdOn + trialPeriod > Date.now() &&
            user.trialPeriod === true ? (
                <p>Usted está en disfrutando del periodo de prueba gratuito.</p>
            ) : null}
            {user.role === "standard" &&
            user.createdOn + trialPeriod < Date.now() &&
            user.trialPeriod === false &&
            user.pay_date === basePayDate ? (
                <p>
                    Su periodo de prueba ha vencido. <Link to="/payment">Suscríbase</Link>
                </p>
            ) : null}
            {user.role === "standard" &&
            user.pay_date + year < Date.now() &&
            user.pay_date > basePayDate ? (
                <p>
                    Su membrecía ha caducado. <Link to="/payment">Renovar</Link>
                </p>
            ) : null}
            {user.role === "member" &&
            user.pay_date + year - 15 * day < Date.now() &&
            user.pay_date > basePayDate ? (
                <p>
                    {`Su membrecía vencerá en ${getDays(user.pay_date)} dia${
                        getDays(user.pay_date) === 1 ? ". " : "s. "
                    }`}
                    <Link to="/payment">Renovar</Link>
                </p>
            ) : null}
        </div>
    );
};

export default RoleNotice;
