import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../services/databaseRead";
import { getFile, uploadPlayerImage, uploadTeamLogos } from "../../services/storage";

import { hideHeader } from "../../redux/userActions";

import { validateImageExtension } from "../../helpers/structureData";

import FormInput from "../../components/FormInput";
import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";

import "./PhotoEditor.scss";
import defaultPic from "../../images/playerthumbnail.png";

const PhotoEditor = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [singlePlayerID, setSinglePlayerID] = useState("");
    const [playerResult, setPlayerResult] = useState({ id: "" });
    const [playerPic, setPlayerPic] = useState("");
    const [message, setMessage] = useState("");
    const [fileUploadPercent, setFileUploadPercent] = useState(0);
    const [teamLogofileUploadPercent, setTeamLogofileUploadPercent] = useState(0);

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    const getPlayerPic = async (key) => {
        try {
            const result = await getFile("players", `thumb_${key}.jpeg`);
            if (result) {
                setPlayerPic(result);
            }
        } catch (e) {
            setPlayerPic(defaultPic);
        }
    };

    const handleUploadPlayerImage = async (event) => {
        const file = event.target.files[0];
        if (file && validateImageExtension(file.name.toLowerCase())) {
            Object.defineProperty(file, "name", {
                writable: true,
                value: `thumb_${playerResult.id}.jpeg`,
            });
            const downloadUrl = await uploadPlayerImage(file, setFileUploadPercent);
            console.log("downloadURL", downloadUrl);
            if (downloadUrl) {
                setFileUploadPercent(100);
                setMessage(`Uploaded image thumb_${playerResult.id}.jpeg`);
            }
        }
    };

    const handleUploadTeamLogo = async (event) => {
        const file = event.target.files[0];
        if (file && validateImageExtension(file.name.toLowerCase())) {
            const downloadUrl = await uploadTeamLogos(file, setTeamLogofileUploadPercent);
            console.log("downloadURL", downloadUrl);
            if (downloadUrl) {
                setFileUploadPercent(100);
                setMessage(`Uploaded to Team Logos`);
            }
        }
    };

    const handleGetOnePlayer = async (event) => {
        event.preventDefault();
        setMessage("");
        if (singlePlayerID) {
            try {
                const result = await getItem("players", singlePlayerID);
                if (result) {
                    console.log(result);
                    setPlayerResult(result);
                    getPlayerPic(result.id);
                } else {
                    setMessage("No results found.");
                }
            } catch (e) {
                setPlayerResult({ id: "" });
                setMessage("Query not performed. Error");
            }
        } else {
            setMessage("Please enter an ID");
        }
    };

    if (user.role !== "photo-editor") return <div>Access Denied</div>;

    return (
        <>
            <HeaderToggle />
            <Overlay />
            <Header />
            <div>
                <div className="message">{message}</div>
            </div>
            <div className="playerPanel">
                <form className="getPlayer" onSubmit={handleGetOnePlayer}>
                    <h4>Get a player with ID</h4>
                    <FormInput
                        placeholder="ID or Key"
                        value={singlePlayerID}
                        onChange={(value) => setSinglePlayerID(value)}
                    />
                    <button type="submit">Get Player</button>
                    {playerResult.id ? (
                        <div className="form">
                            <h4>Player Photo</h4>
                            <img alt="playerImage" src={playerPic} />
                            <input type="file" onChange={handleUploadPlayerImage} />
                            <span className="displayPercent">{fileUploadPercent}</span>
                        </div>
                    ) : null}
                </form>
                <div className="form">
                    <h4>Team Logos</h4>
                    <input type="file" onChange={handleUploadTeamLogo} />
                    <span className="displayPercent">{teamLogofileUploadPercent}</span>
                </div>
            </div>
        </>
    );
};

export default PhotoEditor;
