import React, { useState, useRef } from "react";

import {
    addItemWithId,
    setStats,
    setPlayerStats,
    setGlobalStats,
    setPlayerPrizes,
} from "../../services/databaseWrite";
import {
    csvToJSON,
    buildStats,
    csvToMap,
    buildGlobalInfo,
    csvToPlayerMap,
    csvToYearMetrics,
    csvToPrizeMap,
    validateEquality,
    csvToPayDatesMap,
} from "../../helpers/convert";
import { samplePlayers } from "../../helpers/validation";

import "./Upload.scss";

const Upload = () => {
    const [message, setMessage] = useState(""); //to visually update status.
    //references... main collections
    const allStatsRef = useRef();
    const playersRef = useRef();
    const globalInfoRef = useRef();
    const prizesRef = useRef();

    const toJSON = useRef();
    //strings
    const yearMetricsRef = useRef();
    const yearValRef = useRef();
    const playersValRef = useRef();

    const valMap = useRef();
    const paydates = useRef();

    //MAIN FUNCTIONS to ADD DATA ON FIREBASE

    const addStats = () => {
        const file = allStatsRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const years = buildStats(reader.result); //years is an object
                //console.log(years)
                if (typeof years === "string") {
                    setMessage(years); //if string, means there is an error.
                    return;
                }
                setMessage("Uploading...");
                for (const year in years) {
                    const phases = Object.keys(years[year]); //phases is an array
                    for (const i in phases) {
                        const teams = Object.keys(years[year][phases[i]]); //teams also an array
                        for (const j in teams) {
                            const teamPlayerStats = years[year][phases[i]][teams[j]];
                            //yearDoc, phaseSubCollection, teamDoc, playersObject
                            await setStats(year, phases[i], teams[j], teamPlayerStats);
                            for (const player in teamPlayerStats) {
                                const obj = {};
                                obj[year + phases[i] + teams[j]] =
                                    teamPlayerStats[player];
                                await setPlayerStats(player, obj);
                            }
                        }
                    }
                }
                setMessage("Upload complete!");
            };
        }
    };

    //basic function to add documents, mainly to players.
    const addDocuments = (reference, collection, successMessage, columns) => {
        setMessage("");
        const file = reference.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                //console.log(reader.result);
                const lines = csvToJSON(reader.result);
                //console.log("logging headers", lines.headers)
                if (lines.data.length && lines.headers.length === columns) {
                    if (validateEquality(lines.headers, Object.keys(samplePlayers))) {
                        lines.data.forEach(async (line) => {
                            //console.log(line)
                            try {
                                await addItemWithId(collection, line, line.key);
                                setMessage(successMessage);
                            } catch (e) {
                                setMessage(e.message);
                            }
                        });
                    } else {
                        setMessage(
                            "Error: The key names provided do not match the expected key names."
                        );
                    }
                } else {
                    setMessage(
                        "Error: Empty file or mismatch between expected and actual number of columns"
                    );
                }
            };
        } else {
            setMessage("No File.");
        }
    };

    //add docs to players
    const handleAddPlayers = () => {
        addDocuments(playersRef, "players", "Added to Players", 22);
    };

    //global data by year, phase and team... like managers and positions
    const updateGlobalInfo = () => {
        const file = globalInfoRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const globalStats = buildGlobalInfo(reader.result);
                if (typeof globalStats === "string") {
                    setMessage(globalStats);
                    return;
                }
                for (const year in globalStats) {
                    await setGlobalStats(year, globalStats[year]);
                }
                setMessage("Upload global stats complete.");
            };
        }
    };

    //inject prizes on players
    const injectPrizes = () => {
        const file = prizesRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const prizesMap = csvToPrizeMap(reader.result);
                if (typeof prizesMap === "string") {
                    setMessage(prizesMap); //if string, means there is an error.
                    return;
                }
                setMessage("Adding prizes to players");
                for (const playerCode in prizesMap) {
                    await setPlayerPrizes(playerCode, prizesMap[playerCode]);
                }
                setMessage("Upload of prizes complete.");
            };
        }
    };

    //SIMPLE LOG
    //simple convert to JSON
    const convertToJSON = () => {
        const file = toJSON.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                const lines = csvToJSON(reader.result);
                console.log(JSON.stringify(lines.data));
            };
        }
    };

    //STRINGS ON FIREBASE

    const updateYearMetrics = () => {
        //single string to firebase with main metrics for ERA+ or OPS+ calculations
        const file = yearMetricsRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const lines = csvToYearMetrics(reader.result);
                if (typeof lines === "string") {
                    setMessage(lines); //if string, means there is an error.
                    return;
                }
                const result = await addItemWithId(
                    "yearMetrics",
                    { metrics: JSON.stringify(lines) },
                    "metrics"
                );
                if (result) {
                    setMessage("Updated Yearly metrics and constants");
                }
            };
        }
    };

    const updateYearValidation = () => {
        const file = yearValRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const lines = csvToMap(reader.result);
                if (typeof lines === "string") {
                    setMessage(lines); //if string, means there is an error.
                    return;
                }
                const result = await addItemWithId(
                    "yearValidation",
                    { years: JSON.stringify(lines) },
                    "years"
                );
                if (result) {
                    setMessage("Updated Years for Dropdowns");
                }
            };
        }
    };

    const updatePlayersValidation = () => {
        const file = playersValRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const lines = csvToPlayerMap(reader.result);
                if (typeof lines === "string") {
                    setMessage(lines); //if string, means there is an error.
                    return;
                }
                const result = await addItemWithId(
                    "playersValidation",
                    { players: JSON.stringify(lines) },
                    "players"
                );
                if (result) {
                    setMessage("Updated Players for Dropdowns");
                }
            };
        }
    };

    //SIMPLE LOG

    const validationMap = () => {
        const file = valMap.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const lines = csvToMap(reader.result);
                console.log(JSON.stringify(lines));
            };
        }
    };

    const uploadPayDates = () => {
        const file = paydates.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                const dates = csvToPayDatesMap(reader.result);
                if (typeof dates === "string") {
                    setMessage(dates); //if string, means there is an error.
                    return;
                }
                setMessage("Adding pay dates to players");
                for (const code in dates) {
                    await addItemWithId(
                        "users",
                        { pay_date: parseInt(dates[code]) },
                        code
                    );
                }
                setMessage("Upload of pay dates complete.");
            };
        }
    };

    // const handleDeleteFromCollection = async () => {
    //     const results = await deleteFromCollection('stats');
    //     console.log(results)
    // }

    return (
        <div className="upload">
            <div className="inputArea">
                <h3>Input Area</h3>
                <span>
                    Progress:<span className="message">{message}</span>
                </span>
                <div className="firebase">
                    <h4>Add documents to players</h4>
                    <p>Adds players to firebase - (Collection)</p>
                    <input type="file" onChange={handleAddPlayers} ref={playersRef} />
                </div>
                <div className="firebase">
                    <h4>Add documents to allStats</h4>
                    <p>Add stats to allStats and each player - (Collection)</p>
                    <input type="file" onChange={addStats} ref={allStatsRef} />
                </div>
                <div className="firebase">
                    <h4>Update Global Info</h4>
                    <p>Add global stats and info - (Collection)</p>
                    <input type="file" onChange={updateGlobalInfo} ref={globalInfoRef} />
                </div>
                <div className="firebase">
                    <h4>Inject Player Prizes</h4>
                    <p>Update each players prizes - (Collection)</p>
                    <input type="file" onChange={injectPrizes} ref={prizesRef} />
                </div>
                <div>
                    <h4>Convert to JSON</h4>
                    <p>Simple CSV conversion to JSON. Logs.</p>
                    <input type="file" onChange={convertToJSON} ref={toJSON} />
                </div>
                <div className="firebase">
                    <h4>Season Totals (Complete History)</h4>
                    <p>Yearly constants, one line per tournament - (String)</p>
                    <input
                        type="file"
                        onChange={updateYearMetrics}
                        ref={yearMetricsRef}
                    />
                </div>
                <div className="firebase">
                    <h4>Update Season Codes (Complete History)</h4>
                    <p>Changes the year validation string - (String)</p>
                    <input type="file" onChange={updateYearValidation} ref={yearValRef} />
                </div>
                <div className="firebase">
                    <h4>Update Players Alias</h4>
                    <p>Changes the player validation string - (String)</p>
                    <input
                        type="file"
                        onChange={updatePlayersValidation}
                        ref={playersValRef}
                    />
                </div>
                <div>
                    <h4>Log Validation Map</h4>
                    <p>Console.logs a validation map based on a CSV.</p>
                    <input type="file" onChange={validationMap} ref={valMap} />
                </div>
                <div>
                    <h4>Upload PayDates</h4>
                    <p>Uploads the paydate for a list of users.</p>
                    <input type="file" onChange={uploadPayDates} ref={paydates} />
                </div>
                {/* <div>
                    <h4>Delete from Collection</h4>
                    <button
                        onClick={handleDeleteFromCollection}>
                    Delete from Collection</button>
                </div>                             */}
            </div>
        </div>
    );
};

export default Upload;
