import React, { useState } from "react";
import { getItem } from "../../services/databaseRead";
import { deleteItem, deletePlayerFromAllStats } from "../../services/databaseWrite";

import FormInput from "../FormInput";

import "./PlayerDelete.scss";

const PlayerDelete = () => {
    const [singlePlayerID, setSinglePlayerID] = useState("");
    const [playerResult, setPlayerResult] = useState(null);
    const [message, setMessage] = useState("");

    const handleGetOnePlayer = async (event) => {
        event.preventDefault();
        setMessage("");
        if (singlePlayerID) {
            try {
                const result = await getItem("players", singlePlayerID);
                if (result) {
                    console.log(result);
                    setPlayerResult(result);
                } else {
                    setMessage("No results found.");
                }
            } catch (e) {
                setPlayerResult(null);
                setMessage("Query not performed. Error");
            }
        } else {
            setMessage("Please enter an ID");
        }
    };

    const handleDeletePlayer = async (event) => {
        event.preventDefault();
        const playerStats = Object.keys(playerResult.stats);
        if (playerStats.length) {
            playerStats.forEach(async (statDoc) => {
                await deletePlayerFromAllStats(statDoc, playerResult.id);
            });
        }
        const result = await deleteItem("players", playerResult.id);
        if (result) {
            setMessage(`Player ${playerResult.id} ${playerResult.name} deleted`);
            setPlayerResult(null);
        }
    };

    return (
        <div className="playerPanel">
            <form className="getPlayer" onSubmit={handleGetOnePlayer}>
                <h4>Get a player with ID</h4>
                <FormInput
                    placeholder="ID or Key"
                    value={singlePlayerID}
                    onChange={(value) => setSinglePlayerID(value)}
                />
                <button type="submit">Get Player</button>
                <div className="message">{message}</div>
            </form>
            {playerResult ? (
                <div className="results">
                    <div>
                        <h4>Player Info</h4>
                        <form className="playerInfoForm" onSubmit={handleDeletePlayer}>
                            <label>
                                name:
                                <div>{playerResult.name}</div>
                            </label>
                            <label>
                                number:
                                <div>{playerResult.number}</div>
                            </label>
                            <label>
                                id:
                                <div>{playerResult.id}</div>
                            </label>
                            <p className="warning">
                                Danger zone! Once deleted you cannot reverse the
                                operation.
                            </p>
                            <button type="submit" className="deletePlayerButton">
                                Delete Player
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PlayerDelete;
