const initialState = { years: "", players: "" };

function validationsReducer(state = initialState, action) {
    switch (action.type) {
        case "addYears":
            return { ...state, years: action.payload };
        case "addPlayers":
            return { ...state, players: action.payload };
        default:
            return state;
    }
}

export default validationsReducer;
