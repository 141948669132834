import React from "react";

import { pitchStats } from "../../../helpers/validation";
import { getThirds } from "../../../helpers/structureData";

import TableHeader from "../TableHeader";
import TableCell from "../TableCell";
import TableRow from "../TableRow";
import Anchor from "../../Anchor";

import "./TeamPitchers.scss";

const TeamPitchers = ({ pitchers, reorder, totals, orderedBy }) => {
    const stats = Object.keys(pitchStats);

    const injectAnchor = (obj) => {
        const name = obj["playerDesc"];
        const key = obj["playerCode"];
        const modIpp = obj["ipp"];
        const anchor = <Anchor value={name} href={`/players?key=${key}`}></Anchor>;
        return { ...obj, playerDesc: anchor, ipp: getThirds(modIpp) };
    };

    const modTotals = { ...totals, ipp: getThirds(totals["ipp"]) };

    return (
        <div className="teamPitchers">
            <h3>Lanzadores</h3>
            <div className="tableContainer">
                <table>
                    <thead>
                        <tr>
                            {stats.map((field, index) => {
                                return (
                                    <TableHeader
                                        key={field}
                                        value={pitchStats[field]}
                                        x={`x${index}`}
                                        reorder={reorder}
                                        field={field}
                                        origin="pitcher"
                                    />
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {pitchers.map((player, index) => {
                            const modPlayer = injectAnchor(player);
                            return (
                                <TableRow key={index} y={index}>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={modPlayer[field]}
                                                x={`x${index}`}
                                                header={field}
                                                orderedBy={orderedBy}
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        <tr>
                            {stats.map((field, index) => {
                                return (
                                    <TableCell
                                        key={field + index}
                                        value={modTotals[field]}
                                        x={`x${index}`}
                                        amTotal={true}
                                    />
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TeamPitchers;
