import React from "react";

import "./TableRow.scss";

const TableRow = ({ children, y }) => {
    return (
        <tr className={`tableRow y${y} ${y % 2 === 0 ? "even" : "odd"}`}>{children}</tr>
    );
};

export default TableRow;
