//functions that transform incoming data from firebase into more "manageable" structures, reordering and creating arrays...

import {
    avg,
    obp,
    slg,
    ops,
    yearOps,
    opsPlus,
    pct,
    era,
    whip,
    fip,
    eraPlus,
} from "./calculations";
import { teamCodeToTeam } from "./validation";

//basic function to transform the firebase object returned from a single allStats document.
const allStatsToArray = (obj) => {
    const results = [];
    for (const item in obj) {
        results.push(obj[item]);
    }
    return results;
};

const divide = (nominator, denominator) => {
    return (parseFloat(nominator) / parseFloat(denominator)).toFixed(0);
};

const thirds = (string) => {
    switch (true) {
        case string[0] === "3":
            return ".1";
        case string[0] === "6":
            return ".2";
        default:
            return "";
    }
};

const getThirds = (number) => {
    const wholePart = number.toString().split(".")[0];
    const maybeDecimalPart = number.toString().split(".")[1];
    const decimalPart =
        maybeDecimalPart !== undefined ? maybeDecimalPart.substring(0, 1) : "";
    if (decimalPart === "9") {
        return (parseInt(wholePart) + 1).toString();
    } else {
        const third = thirds(decimalPart);
        return wholePart + third;
    }
};

const removeFromArray = (arr, itemsToRemove) => {
    const filtered = arr.filter((elem) => {
        return !itemsToRemove.includes(elem);
    });
    return filtered;
};

const removeAccents = (string) => {
    return string
        .replace(/[á]/g, "a")
        .replace(/[é]/g, "e")
        .replace(/[í]/g, "i")
        .replace(/[ó]/g, "o")
        .replace(/[ú]/g, "u");
};

//sorts and array of objects, default is descending.
const sortArrayBy = (array, property, direction = "descending") => {
    const sorted = array.sort((a, b) => {
        return direction === "descending"
            ? b[property] - a[property]
            : a[property] - b[property];
    });
    return sorted;
};

//takes an allstat document obj, changes it to array, and does some modifications.
const teamsSeparatePlayers = (obj) => {
    const intoArray = allStatsToArray(obj);
    const batters = [];
    const pitchers = [];
    intoArray.forEach((player) => {
        if (parseInt(player.jj) > 0) {
            //only count batter when jj juegos jugados over 0
            batters.push(player);
        }
        if (parseInt(player.gp) > 0) {
            //same for games pitched.
            pitchers.push(player);
        }
    });
    return { bat: sortArrayBy(batters, "jj"), pitch: sortArrayBy(pitchers, "gp") };
};

const playersSeparateStats = (obj) => {
    const keys = Object.keys(obj);
    const result = { bat: { SR: [], playoffs: [] }, pitch: { SR: [], playoffs: [] } };
    keys.forEach((linekey) => {
        if (parseInt(obj[linekey]["jj"]) > 0) {
            if (obj[linekey]["etapaDesc"] === "SR") {
                result["bat"]["SR"].push(obj[linekey]);
            } else {
                //if it's not SR, then it's RR, SM or SF
                result["bat"]["playoffs"].push(obj[linekey]);
            }
        }
        if (parseInt(obj[linekey]["gp"]) > 0) {
            if (obj[linekey]["etapaDesc"] === "SR") {
                result["pitch"]["SR"].push(obj[linekey]);
            } else {
                //if it's not SR, then it's RR, SM or SF
                result["pitch"]["playoffs"].push(obj[linekey]);
            }
        }
    });
    return result;
};

const addBatCalculations = (playerObj, globalObj) => {
    const obj = { ...playerObj };
    const glob = { ...globalObj };
    const calcObp = obp(obj["h"], obj["bb"], obj["hbp"], obj["ab"], obj["sf"]);
    const calcSlg = slg(obj["tb"], obj["ab"]);
    const calcYearOps = yearOps(glob["oba1"], glob["oba2"], glob["tb"], glob["ab"]);
    const calcOps = ops(calcObp, calcSlg);
    const opsP = opsPlus(calcOps, calcYearOps);
    obj["avg"] = avg(obj["h"], obj["ab"]);
    obj["obp"] = calcObp;
    obj["slg"] = calcSlg;
    obj["ops"] = calcOps;
    obj["opsPlus"] = opsP;
    return obj;
};

const addPitchCalculations = (playerObj, globalObj) => {
    const obj = { ...playerObj };
    const glob = { ...globalObj };
    const eraG = era(glob["erp"], glob["ipp"]);
    const eraCalc = era(obj["erp"], obj["ipp"]);
    obj["pct"] = pct(obj["wp"], obj["decp"]);
    obj["era"] = eraCalc;
    obj["whip"] = whip(obj["hp"], obj["bbp"], obj["ipp"]);
    obj["fip"] = fip(
        obj["hrp"],
        obj["bbp"],
        obj["hbpp"],
        obj["sop"],
        obj["ipp"],
        glob["fipconst"]
    );
    obj["eraPlus"] = eraPlus(eraG, eraCalc);
    obj["eraGlobal"] = eraG;
    return obj;
};

const processPlayerObject = (obj, playerType, globalObj) => {
    const { SR, playoffs } = obj;
    if (playerType === "pitch") {
        //add calculations
        const processedSR = [...SR].map((line) => {
            const processed = addPitchCalculations(line, globalObj[line["seasonCode"]]);
            return {
                ...processed,
                seasonDesc: processed["seasonDesc"].substr(0, 4),
                teamFullDesc: teamCodeToTeam[processed["teamDesc"]],
            };
        });
        const processedPlayoffs = [...playoffs].map((line) => {
            const processed = addPitchCalculations(line, globalObj[line["seasonCode"]]);
            return {
                ...processed,
                seasonDesc: processed["seasonDesc"].substr(0, 4),
                teamFullDesc: teamCodeToTeam[processed["teamDesc"]],
            };
        }); //then sort them
        return {
            SR: sortArrayBy(processedSR, "seasonDesc", "ascending"),
            playoffs: sortArrayBy(processedPlayoffs, "seasonDesc", "ascending"),
        };
    } else if (playerType === "bat") {
        const processedSR = [...SR].map((line) => {
            const processed = addBatCalculations(line, globalObj[line["seasonCode"]]);
            return {
                ...processed,
                seasonDesc: processed["seasonDesc"].substr(0, 4),
                teamFullDesc: teamCodeToTeam[processed["teamDesc"]],
            };
        });
        const processedPlayoffs = [...playoffs].map((line) => {
            const processed = addBatCalculations(line, globalObj[line["seasonCode"]]);
            return {
                ...processed,
                seasonDesc: processed["seasonDesc"].substr(0, 4),
                teamFullDesc: teamCodeToTeam[processed["teamDesc"]],
            };
        }); //then sort them
        return {
            SR: sortArrayBy(processedSR, "seasonDesc", "ascending"),
            playoffs: sortArrayBy(processedPlayoffs, "seasonDesc", "ascending"),
        };
    }
};

//the array of objects to totalize, the fields we want to totalize, and optional array declaring which values we want average instead of total
const totalize = (array, fields, averages = []) => {
    const result = array.reduce((total, current) => {
        const obj = {};
        fields.forEach((field) => {
            const calc = parseInt(total[field]) + parseInt(current[field]);
            if (!isNaN(calc)) {
                obj[field] = parseFloat(total[field]) + parseFloat(current[field]);
            }
        });
        return obj;
    });
    if (averages.length) {
        //in case we introduce an array with averages, then we will change some totals to averages.
        const avgs = {};
        averages.forEach((field) => {
            const avg = divide(result[field], array.length);
            avgs[field] = isNaN(avg) ? "" : avg;
        });
        return { ...result, ...avgs };
    } else {
        return result;
    }
};

const averageYearMetrics = (playerPhaseArray, globalObj) => {
    const result = [];
    playerPhaseArray.forEach((playedYear) => {
        result.push(globalObj[playedYear["seasonCode"]]);
    });
    const total = totalize(
        result,
        ["ab", "erp", "ipp", "oba1", "oba2", "tb", "woba", "fipconst"],
        ["fipconst"]
    );
    return total;
};

const totalizePlayerArray = (
    array,
    fields,
    averages,
    playerType,
    globalObj,
    seasonDesc = "Totales"
) => {
    if (playerType === "pitch") {
        const result = addPitchCalculations(
            totalize(array, fields, averages),
            averageYearMetrics(array, globalObj)
        );
        return {
            ...result,
            seasonDesc: seasonDesc,
            age: "",
            ipp: getThirds(result["ipp"]),
        };
    } else if (playerType === "bat") {
        return {
            ...addBatCalculations(
                totalize(array, fields, averages),
                averageYearMetrics(array, globalObj)
            ),
            seasonDesc: seasonDesc,
            age: "",
        };
    }
};

const mapArrayIntoObject = (array, field) => {
    const obj = {};
    array.forEach((item) => {
        obj[item[field]] = [];
    });
    array.forEach((item) => {
        obj[item[field]].push(item);
    });
    return { obj, groups: Object.keys(obj) };
};

//returns the last text after the dot on a string (.jpg or .mp4, etc)
const getFileExtension = (string) => {
    return string.split(".")[string.split(".").length - 1];
};

//returns true if string has an extension that's accepted
const validateImageExtension = (string) => {
    const accepted = ["pdf", "jpeg", "jpg", "gif", "png"];
    return accepted.includes(getFileExtension(string)) ? true : false;
};

//calculate stats by 50 metrics for SR on players

const getSR50StatsPitcher = (SRTotals) => {
    const removeFields = ["gp", "whip", "pct", "era", "eraPlus"];
    const gamesSoFar = parseFloat(SRTotals["gp"]) + parseFloat(SRTotals["gs"]);
    const newObj = {};
    for (let prop in SRTotals) {
        const numberToProcess = parseFloat(SRTotals[prop]);
        const newValue =
            isNaN(numberToProcess) ||
            numberToProcess === undefined ||
            removeFields.includes(prop)
                ? ""
                : (numberToProcess / (gamesSoFar / 20)).toFixed(0);
        newObj[prop] = newValue;
    }
    return { ...newObj, seasonDesc: "Promedio 50 Juegos" };
};

const getSR50StatsBatter = (SRTotals) => {
    const removeFields = ["jj", "avg", "obp", "slg", "ops"];
    const gamesSoFar = SRTotals["jj"];
    const newObj = {};
    for (let prop in SRTotals) {
        const numberToProcess = parseFloat(SRTotals[prop]);
        const newValue =
            isNaN(numberToProcess) ||
            numberToProcess === undefined ||
            removeFields.includes(prop)
                ? ""
                : ((numberToProcess / gamesSoFar) * 50).toFixed(0);
        newObj[prop] = newValue;
    }
    return { ...newObj, seasonDesc: "Promedio 50 Juegos" };
};

export {
    allStatsToArray,
    teamsSeparatePlayers,
    playersSeparateStats,
    sortArrayBy,
    addBatCalculations,
    addPitchCalculations,
    totalize,
    divide,
    processPlayerObject,
    totalizePlayerArray,
    removeAccents,
    removeFromArray,
    mapArrayIntoObject,
    getThirds,
    validateImageExtension,
    getSR50StatsPitcher,
    getSR50StatsBatter,
};
