import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { logout } from "../../services/auth";
import { hideHeader, setUser } from "../../redux/userActions";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";

import "./Settings.scss";

const Settings = ({ history }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    const user = useSelector((state) => state.user);

    const handleLogOut = () => {
        dispatch(setUser({ role: "" }));
        logout();
        history.push("/");
    };

    return (
        <div className="settings">
            <HeaderToggle />
            <Overlay />
            <Header />
            <div className="content">
                <h3>Configuración</h3>
                {user.role ? (
                    <>
                        <div>
                            Usted está loggeado: <span>{user.email}</span>
                            {user.role === "pending" && (
                                <div>Su email esta pendiente por validar.</div>
                            )}
                        </div>
                        <div>
                            <button onClick={handleLogOut}>Cerrar sesión</button>
                        </div>
                    </>
                ) : (
                    <div>
                        Usted no ha iniciado sesión. Puede hacer login{" "}
                        <Link to="./login">aquí.</Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Settings;
