import React from "react";

import "./LittleBurger.scss";

const LittleBurger = ({ handleToggle }) => {
    return (
        <div className="littleBurger" onClick={handleToggle}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
        </div>
    );
};

export default LittleBurger;
