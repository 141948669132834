import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { hideHeader } from "../../redux/userActions";

import "./Overlay.scss";

const Overlay = () => {
    const displayState = useSelector((state) => state.headerToggle.className);

    const dispatch = useDispatch();

    const handleOverlayClick = () => {
        dispatch(hideHeader());
    };

    return <div className={`overlay ${displayState}`} onClick={handleOverlayClick}></div>;
};

export default Overlay;
