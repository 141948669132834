import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { hideHeader } from "../../redux/userActions";

import Upload from "../../components/Upload";
import Read from "../../components/Read";
import PlayerPanel from "../../components/UpdatePanel";
import UsersPanel from "../../components/UsersPanel";
import PlayerDelete from "../../components/PlayerDelete";
import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import Docs from "../../components/Docs";

import "./Manage.scss";

const Manage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [tab, setTab] = useState("create");

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    if (user.role !== "admin") return <div>Access Denied</div>;

    return (
        <div className="manage">
            <HeaderToggle />
            <Overlay />
            <Header />
            <h3>Admin Panel</h3>
            <div className="tabs">
                <button
                    onClick={() => {
                        setTab("create");
                    }}
                    className={tab === "create" ? "active" : ""}
                >
                    Create
                </button>
                <button
                    onClick={() => {
                        setTab("read");
                    }}
                    className={tab === "read" ? "active" : ""}
                >
                    Read
                </button>
                <button
                    onClick={() => {
                        setTab("updatePanel");
                    }}
                    className={tab === "updatePanel" ? "active" : ""}
                >
                    Update
                </button>
                <button
                    onClick={() => {
                        setTab("usersPanel");
                    }}
                    className={tab === "usersPanel" ? "active" : ""}
                >
                    Users
                </button>
                <button
                    onClick={() => {
                        setTab("playerDelete");
                    }}
                    className={tab === "playerDelete" ? "active" : ""}
                >
                    Delete
                </button>
                <button
                    onClick={() => {
                        setTab("docs");
                    }}
                    className={tab === "docs" ? "active" : ""}
                >
                    Docs
                </button>
            </div>
            {tab === "create" && <Upload />}
            {tab === "read" && <Read />}
            {tab === "updatePanel" && <PlayerPanel />}
            {tab === "usersPanel" && <UsersPanel />}
            {tab === "playerDelete" && <PlayerDelete />}
            {tab === "docs" && <Docs />}
        </div>
    );
};

export default Manage;
