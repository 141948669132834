import React from "react";

import PricePlan from "../PricePlan";

import "./Notice.scss";

const Notice = ({ requiredMemebership }) => {
    return (
        <div className="notice">
            <p>Conviértase en un {requiredMemebership} para acceder a este reporte.</p>
            <PricePlan />
        </div>
    );
};

export default Notice;
