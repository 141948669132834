//fixed maps or objects that will help for users selections, dropdowns, etc.

const flip = (obj) => {
    const ret = {};
    Object.keys(obj).forEach((key) => {
        ret[obj[key]] = key;
    });
    return ret;
};

const phases = { SR: "SR", SM: "SM", SF: "SF", RR: "RR", MP: "MP" };

const teams = {
    "Aguilas Cibaeñas": "AC",
    "Azucareros del Este": "AE",
    "Caimanes del Sur": "CS",
    "Estrellas Orientales": "EO",
    "Gigantes del Cibao": "GC",
    "Gigantes del Nordeste": "GN",
    "Leones del Escogido": "LE",
    "Pollos Nacionales": "PN",
    "Tigres del Licey": "TL",
    "Toros del Este": "TE",
};

const teamCodeToTeam = flip(teams);

const batStats = {
    playerDesc: "Nombre",
    age: "Edad",
    pos: "POS",
    jj: "JJ",
    app: "APP",
    ab: "AB",
    c: "C",
    h: "H",
    h1: "1B",
    h2: "2B",
    h3: "3B",
    hr: "HR",
    rbi: "RBI",
    bb: "BB",
    so: "SO",
    sb: "SB",
    cs: "CS",
    avg: "AVG",
    obp: "OBP",
    slg: "SLG",
    ops: "OPS",
    opsPlus: "OPS+",
    tb: "TB",
    hbp: "HBP",
    ibb: "IBB",
    sf: "SF",
    s: "S",
    dp: "DP",
};

const pitchStats = {
    playerDesc: "Nombre",
    age: "Edad",
    wp: "W",
    lp: "L",
    pct: "PCT",
    era: "ERA",
    eraPlus: "ERA+",
    gp: "GP",
    gs: "GS",
    cgp: "CG",
    shop: "SHO",
    svp: "SV",
    ipp: "IP",
    hp: "H",
    rp: "R",
    erp: "ER",
    hrp: "HR",
    bbp: "BB",
    ibbp: "IBB",
    sop: "SO",
    hbpp: "HBP",
    wpp: "WP",
    bep: "BE",
    decp: "DEC",
    bsp: "BS",
    whip: "WHIP",
    fip: "FIP",
};

const playerBatStats = {
    seasonDesc: "Año",
    age: "Edad",
    teamFullDesc: "Equipo",
    etapaDesc: "Etapa",
    jj: "JJ",
    app: "APP",
    ab: "AB",
    c: "C",
    h: "H",
    h1: "1B",
    h2: "2B",
    h3: "3B",
    hr: "HR",
    rbi: "RBI",
    bb: "BB",
    so: "SO",
    sb: "SB",
    cs: "CS",
    avg: "AVG",
    obp: "OBP",
    slg: "SLG",
    ops: "OPS",
    opsPlus: "OPS+",
    tb: "TB",
    hbp: "HBP",
    ibb: "IBB",
    sf: "SF",
    s: "S",
    dp: "DP",
};

const playerPitchStats = {
    seasonDesc: "Año",
    age: "Edad",
    teamFullDesc: "Equipo",
    etapaDesc: "Etapa",
    wp: "W",
    lp: "L",
    pct: "PCT",
    era: "ERA",
    eraPlus: "ERA+",
    gp: "GP",
    gs: "GS",
    cgp: "CG",
    shop: "SHO",
    svp: "SV",
    ipp: "IP",
    hp: "H",
    rp: "R",
    erp: "ER",
    hrp: "HR",
    bbp: "BB",
    ibbp: "IBB",
    sop: "SO",
    hbpp: "HBP",
    wpp: "WP",
    bep: "BE",
    decp: "DEC",
    bsp: "BS",
    whip: "WHIP",
    fip: "FIP",
};

const positionMap = {
    C: "Catcher",
    P: "Pitcher",
    "1B": "Primera Base",
    "2B": "Segunda Base",
    "3B": "Tercera Base",
    SS: "Shortstop",
    LF: "Leftfielder",
    CF: "Centerfielder",
    RF: "Rightfielder",
    DH: "Bateador Designado",
};

const ascPitchMetrics = ["fip", "era", "whip"];

const sampleAllStats = {
    key: "44SF3458AC",
    seasonCode: "44",
    seasonDesc: "1997-98",
    etapaCode: "3",
    etapaDesc: "SF",
    playerCode: "3458",
    playerDesc: "Hector Ramírez",
    teamCode: "3",
    teamDesc: "AC",
    franchise: "AC",
    jj: "0",
    app: "0",
    ab: "0",
    c: "0",
    h: "0",
    h1: "0",
    h2: "0",
    h3: "0",
    hr: "0",
    rbi: "0",
    bb: "0",
    so: "0",
    sb: "0",
    cs: "0",
    hbp: "0",
    ibb: "0",
    sf: "0",
    s: "0",
    dp: "0",
    tb: "0",
    wp: "0",
    lp: "0",
    gp: "2",
    gs: "0",
    cgp: "0",
    shop: "0",
    svp: "0",
    ipp: "6",
    hp: "11",
    rp: "5",
    erp: "5",
    bbp: "4",
    ibbp: "3",
    sop: "5",
    hbpp: "0",
    wpp: "0",
    bep: "31",
    decp: "0",
    bsp: "0",
    hrp: "1",
    pos: "P",
    age: "26",
    bats: "Derecha",
    throws: "Derecha",
    weight: "218",
    height: "6 3",
    natimp: "DOM",
    country: "República Dominicana",
    draftedRound: "-",
    draftedYear: "-",
    draftedTeam: "-",
    draftedPick: "-",
    debut: "1994-95 a 2006-07",
};

const samplePlayers = {
    key: "1",
    name: "Tommie Aaron",
    number: "18",
    alias: "-",
    url: "http://www.baseball-reference.com/players/a/aaronto01.shtml",
    weight: "190",
    height: "6 3",
    bats: "Derecha",
    throw: "Derecha",
    bornday: "5-Aug-1939",
    bornLocation: "Mobile AL - USA",
    died: "16-Aug-1984",
    pos: "1B",
    comment: "Hermano de Hank Aaron",
    Transactions: "-",
    natimp: "IMP",
    country: "USA",
    DraftedRound: "-",
    DraftedYear: "-",
    DraftedTeam: "-",
    DraftedPick: "-",
    debut: "nnn",
};

const sampleGlobalStats = {
    key: "1",
    year: "1951",
    phase: "SF",
    team: "TL",
    jj: "5",
    g: "4",
    p: "1",
    pct: "0.8",
    record: "4-1",
    place: "1er lugar",
    managers: "Manuel Henríquez (4-1)",
    founded: "1907",
    stadium: "Estadio La Normal",
    championships: "1",
    tournamentList: "1951",
    teamlogo: "TL1",
    champ: "TL",
};

const samplePrizes = {
    player: "48",
    season: "1986-87",
    premio: "Lanzador del año",
};

const sampleYearMetrics = {
    key: "1",
    year: "1951",
    oba1: "2762",
    oba2: "8373",
    tb: "2487",
    ab: "7469",
    runspergame: "4.22",
    woba: "0.3009691867",
    wobascale: "1.19",
    fipconst: "2.09",
    ipp: "1937.999",
    erp: "707",
    champ: "TL",
    championships: "1",
};

const sampleYearValidation = { seasonDesc: "1951", seasonCode: "nn" };

const samplePlayerValidation = {
    key: "1",
    name: "Tommie Aaron",
    alias: "-",
    debut: "1964-65 a 1967-68",
};

export {
    phases,
    teams,
    batStats,
    pitchStats,
    positionMap,
    playerPitchStats,
    playerBatStats,
    teamCodeToTeam,
    ascPitchMetrics,
    sampleAllStats,
    samplePlayers,
    sampleGlobalStats,
    samplePrizes,
    sampleYearMetrics,
    sampleYearValidation,
    samplePlayerValidation,
};
