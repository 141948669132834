import React, { Component } from "react";
import PropTypes from "prop-types";

import { removeAccents } from "../../helpers/structureData";

import "./Autocomplete.scss";

class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
    };

    static defaultProps = {
        suggestions: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
        };
    }

    onChange = (event) => {
        event.preventDefault();
        const { suggestions } = this.props;
        const userInput = event.currentTarget.value;

        //filter out suggestions without the user's input
        const filteredSuggestions = suggestions.filter((suggestion) => {
            return (
                removeAccents(suggestion.toLowerCase()).indexOf(userInput.toLowerCase()) >
                -1
            );
        });

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: event.currentTarget.value,
        });
    };

    onClick = (event) => {
        //on click means the user has selected one of the suggestions
        this.setState(
            {
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: event.currentTarget.innerText,
            },
            () => {
                //this is executed after set state
                this.props.getSearch(this.state.userInput);
            }
        );
    };

    onKeyDown = (event) => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        //user presses enter.
        if (event.keyCode === 13) {
            this.setState(
                {
                    activeSuggestion: 0,
                    showSuggestions: false,
                    userInput: filteredSuggestions[activeSuggestion],
                },
                () => {
                    //this is executed after set state
                    this.props.getSearch(this.state.userInput);
                }
            ); //user presses up arrow
        } else if (event.keyCode === 38) {
            if (activeSuggestion === 0) {
                return; //cannot go above index 0
            }
            this.setState({
                activeSuggestion: activeSuggestion - 1,
            }); //user presses down arrow
        } else if (event.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput },
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.slice(0, 10).map((suggestion, index) => {
                            let className;

                            //flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion + index}
                                    onClick={onClick}
                                >
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                //when there are no suggestions...
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No hay sugerencias.</em>
                    </div>
                );
            }
        }

        return (
            <>
                <input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    className="autocomplete"
                    value={userInput}
                />
                {suggestionsListComponent}
            </>
        );
    }
}

export default Autocomplete;
