import React from "react";

import { playerPitchStats } from "../../../helpers/validation";
import {
    totalizePlayerArray,
    removeFromArray,
    mapArrayIntoObject,
    getThirds,
    getSR50StatsPitcher,
} from "../../../helpers/structureData";

import TableHeader from "../TableHeader";
import TableCell from "../TableCell";
import TableRow from "../TableRow";
import Anchor from "../../Anchor";

import "./PlayerPitch.scss";

const PlayerPitch = ({ pitchData, reorder, orderedBy, yearMetrics }) => {
    const stats = Object.keys(playerPitchStats);
    const totalStats = removeFromArray(stats, ["teamFullDesc", "age", "etapaDesc"]);
    const { SR, playoffs } = pitchData;

    const injectAnchor = (obj) => {
        const team = obj["teamDesc"];
        const phase = obj["etapaDesc"];
        const year = obj["seasonCode"];
        const teamName = obj["teamFullDesc"];
        const modIpp = obj["ipp"];
        const anchor = (
            <Anchor
                value={teamName}
                href={`teams?year=${year}&phase=${phase}&team=${team}`}
            ></Anchor>
        );
        return { ...obj, teamFullDesc: anchor, ipp: getThirds(modIpp) };
    };

    const SRTotals = SR.length
        ? totalizePlayerArray(
              SR,
              Object.keys(playerPitchStats),
              ["age"],
              "pitch",
              yearMetrics
          )
        : undefined;
    const playoffsTotals = playoffs.length
        ? totalizePlayerArray(
              playoffs,
              Object.keys(playerPitchStats),
              ["age"],
              "pitch",
              yearMetrics
          )
        : undefined;
    const allTotals = totalizePlayerArray(
        [...SR, ...playoffs],
        Object.keys(playerPitchStats),
        ["age"],
        "pitch",
        yearMetrics
    );
    const SR50Stats = SRTotals === undefined ? undefined : getSR50StatsPitcher(SRTotals);

    return (
        <div className="playerPitch">
            {SR.length ? (
                <>
                    <h3>Lanzador - Serie Regular</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerPitchStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                                origin="PitchSR"
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {SR.map((player, index) => {
                                    const modPlayer = injectAnchor(player);
                                    return (
                                        <TableRow key={index} y={index}>
                                            {stats.map((field, index) => {
                                                //console.log(player.seasonDesc, "era",player.era, "eraplus", player.eraPlus, "erp", player.erp, "ipp",player.ipp, "eraglob", player.eraGlobal )
                                                return (
                                                    <TableCell
                                                        key={field + index}
                                                        value={modPlayer[field]}
                                                        x={`x${index}`}
                                                        header={field}
                                                        orderedBy={orderedBy["PitchSR"]}
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={SRTotals[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                            />
                                        );
                                    })}
                                </tr>
                                <tr>
                                    {SR50Stats
                                        ? stats.map((field, index) => {
                                              return (
                                                  <TableCell
                                                      key={field + index}
                                                      value={SR50Stats[field]}
                                                      x={`x${index}`}
                                                      amTotal={true}
                                                      className="special"
                                                  />
                                              );
                                          })
                                        : null}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div></div>
            )}
            {playoffs.length ? (
                <>
                    <h3>Lanzador - Playoffs</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerPitchStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                                origin="PitchPlayoffs"
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {playoffs.map((player, index) => {
                                    const modPlayer = injectAnchor(player);
                                    return (
                                        <TableRow key={index} y={index}>
                                            {stats.map((field, index) => {
                                                return (
                                                    <TableCell
                                                        key={field + index}
                                                        value={modPlayer[field]}
                                                        x={`x${index}`}
                                                        header={field}
                                                        orderedBy={
                                                            orderedBy["PitchPlayoffs"]
                                                        }
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                <tr>
                                    {stats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={playoffsTotals[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                            />
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {mapArrayIntoObject(playoffs, "etapaDesc")["groups"].length > 0 && (
                        <div className="tableContainer">
                            <h5>Total Playoffs</h5>
                            <table>
                                <thead>
                                    <tr>
                                        {totalStats.map((field, index) => {
                                            return (
                                                <TableHeader
                                                    key={field}
                                                    value={playerPitchStats[field]}
                                                    x={`x${index}`}
                                                    reorder={reorder}
                                                    field={field}
                                                />
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapArrayIntoObject(playoffs, "etapaDesc")[
                                        "groups"
                                    ].map((phase, index) => {
                                        return (
                                            <TableRow key={index} y={index}>
                                                {totalStats.map((field, index) => {
                                                    return (
                                                        <TableCell
                                                            key={field + index}
                                                            value={
                                                                totalizePlayerArray(
                                                                    mapArrayIntoObject(
                                                                        playoffs,
                                                                        "etapaDesc"
                                                                    )["obj"][phase],
                                                                    Object.keys(
                                                                        playerPitchStats
                                                                    ),
                                                                    [],
                                                                    "pitch",
                                                                    yearMetrics,
                                                                    `Total ${phase}`
                                                                )[field]
                                                            }
                                                            x={`x${index}`}
                                                            header={field}
                                                        />
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                    <tr>
                                        {totalStats.map((field, index) => {
                                            return (
                                                <TableCell
                                                    key={field + index}
                                                    value={
                                                        totalizePlayerArray(
                                                            playoffs,
                                                            Object.keys(playerPitchStats),
                                                            [],
                                                            "pitch",
                                                            yearMetrics
                                                        )[field]
                                                    }
                                                    x={`x${index}`}
                                                    amTotal={true}
                                                />
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            ) : (
                <div></div>
            )}
            {SR.length && playoffs.length ? ( //for grand totals of both playoffs and SR
                <>
                    <h3>Gran Total Lanzador</h3>
                    <div className="tableContainer">
                        <table>
                            <thead>
                                <tr>
                                    {totalStats.map((field, index) => {
                                        return (
                                            <TableHeader
                                                key={field}
                                                value={playerPitchStats[field]}
                                                x={`x${index}`}
                                                reorder={reorder}
                                                field={field}
                                            />
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {totalStats.map((field, index) => {
                                        return (
                                            <TableCell
                                                key={field + index}
                                                value={allTotals[field]}
                                                x={`x${index}`}
                                                amTotal={true}
                                            />
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default PlayerPitch;
