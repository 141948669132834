import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";

import { getCollection } from "../../services/databaseRead";
import { addItemWithId } from "../../services/databaseWrite";

import { year, basePayDate, day } from "../../helpers/variables";

import FormInput from "../FormInput";
import Dropdown from "../Dropdown";

import "./UsersPanel.scss";

const UsersPanel = () => {
    const [users, setUsers] = useState([]);
    const [queriedUsers, setQueriedUsers] = useState([]);
    const [message, setMessage] = useState("");

    const [change, setChange] = useState({ id: "" });
    const [editingUser, setEditingUser] = useState("");
    const [query, setQuery] = useState("");
    const [orderBy, setOrderBy] = useState({ orderBy: "createdOn" });

    const orderByOptions = {
        "Created On": "createdOn",
        Email: "email",
        "Pay Date": "pay_date",
        Role: "role",
        Trial: "trialPeriod",
    };

    const handleGetUsers = async () => {
        setUsers([]);
        setQuery("");
        const userData = await getCollection("users", orderBy.orderBy);
        if (userData) {
            setUsers(userData);
            setQueriedUsers(userData);
        } else {
            setMessage("No results found.");
        }
    };

    const handleMyRoleChange = (id, newRole, email) => {
        setEditingUser(email + " => Role " + newRole);
        setChange({ id: id, role: newRole });
    };

    const handleMyTrialPeriodChange = (id, newTrialPeriod, email) => {
        setEditingUser(email + " => Trial Period " + newTrialPeriod);
        const bool = newTrialPeriod === "True" ? true : false;
        setChange({ id: id, trialPeriod: bool });
    };

    const handleSave = async () => {
        if (change.id) {
            const result = await addItemWithId("users", change, change.id);
            if (result) {
                setChange({ id: "" });
            }
        }
    };

    const handleQueryChange = (value) => {
        setQuery(value);
        if (value === "") {
            setQueriedUsers(users);
        } else {
            const filtered = [...queriedUsers].filter((user) => {
                return user.email.toLowerCase().includes(value.toLowerCase());
            });
            setQueriedUsers(filtered);
        }
    };

    const dateToTimestamp = (date) => {
        return Date.parse(date);
    };

    const handlePayDayChange = async (day, id, email) => {
        if (!isNaN(dateToTimestamp(day))) {
            addItemWithId("users", { pay_date: dateToTimestamp(day) }, id);
            setEditingUser("Updated " + email + " paydate.");
        }
    };

    const getAlarmColor = (payDate) => {
        const today = +new Date();
        if (payDate > basePayDate) {
            if (payDate + year < today) {
                return "datepicker_red";
            } else if (payDate + year - 15 * day < today) {
                return "datepicker_orange";
            } else {
                return "datepicker_blue";
            }
        }
    };

    return (
        <div className="usersPanel">
            <div className="commandBlock">
                <button className="getUsersButton" onClick={handleGetUsers}>
                    Get Users
                </button>
                <div>
                    <span>Order:</span>
                    <Dropdown
                        options={orderByOptions}
                        field={"orderBy"}
                        defaultOption={0}
                        getQueryData={setOrderBy}
                    />
                </div>

                {users.length > 0 ? (
                    <span className="editing">Editing User: {editingUser}</span>
                ) : (
                    <></>
                )}
            </div>
            {users.length > 0 ? (
                <div className="filterBox">
                    <FormInput
                        placeholder="Search"
                        value={query}
                        onChange={handleQueryChange}
                    />
                </div>
            ) : (
                <></>
            )}

            <span>{message}</span>

            {users.length > 0 ? (
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <th>Count</th>
                                {/* <th>ID</th> */}
                                <th>Email</th>
                                <th>Creation Date</th>
                                <th>Payment Date</th>
                                <th>Current Role</th>
                                <th>Trial Period</th>
                                <th>New Role</th>
                                <th>New Trial</th>
                            </tr>
                            {queriedUsers.map((user, index) => {
                                return (
                                    <tr key={user.email}>
                                        <td>{index + 1}</td>
                                        {/* <td>{user.id}</td> */}
                                        <td>{user.email}</td>
                                        <td>
                                            {new Date(
                                                user.createdOn
                                            ).toLocaleDateString()}
                                        </td>
                                        <td className={getAlarmColor(user.pay_date)}>
                                            <DayPickerInput
                                                formatDate={formatDate}
                                                onDayChange={(day) =>
                                                    handlePayDayChange(
                                                        day,
                                                        user.id,
                                                        user.email
                                                    )
                                                }
                                                placeholder={`${formatDate(
                                                    new Date(user.pay_date)
                                                )}`}
                                                value={
                                                    user.pay_date > basePayDate
                                                        ? `${formatDate(
                                                              new Date(user.pay_date)
                                                          )}`
                                                        : ""
                                                }
                                            />
                                        </td>
                                        <td>{user.role}</td>
                                        <td>{user.trialPeriod ? "True" : "False"}</td>
                                        <td>
                                            <select
                                                value={change[user.id]}
                                                onChange={(event) =>
                                                    handleMyRoleChange(
                                                        user.id,
                                                        event.target.value,
                                                        user.email
                                                    )
                                                }
                                            >
                                                <option>{""}</option>
                                                <option>member</option>
                                                <option>standard</option>
                                                <option>photo-editor</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                value={change[user.id]}
                                                onChange={(event) =>
                                                    handleMyTrialPeriodChange(
                                                        user.id,
                                                        event.target.value,
                                                        user.email
                                                    )
                                                }
                                            >
                                                <option>{""}</option>
                                                <option>True</option>
                                                <option>False</option>
                                            </select>
                                        </td>
                                        <td>
                                            {user.id === change.id ? (
                                                <button onClick={handleSave}>
                                                    Save Change
                                                </button>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default UsersPanel;
