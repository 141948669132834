import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../images/logoWinterball.png";

import "./Header.scss";

const Header = () => {
    const displayState = useSelector((state) => state.headerToggle.className);
    const userRole = useSelector((state) => state.user.role);

    return (
        <header className={`header ${displayState}`}>
            <div className="left">
                <div>
                    <img src={logo} alt="WinterBallData Logo" />
                </div>
                <NavLink to={"./home"} activeClassName="activeRoute">
                    Inicio
                </NavLink>
                <NavLink to={"./teams"} activeClassName="activeRoute">
                    Equipos
                </NavLink>
                <NavLink to={"./players"} activeClassName="activeRoute">
                    Jugadores
                </NavLink>
                {/* <NavLink to={'./advanced'} activeClassName='activeRoute'>Avanzado</NavLink> */}
            </div>
            <div className="right">
                {userRole === "admin" && (
                    <NavLink to={"./manage"} activeClassName="activeRoute">
                        Manage
                    </NavLink>
                )}
                {userRole === "photo-editor" && (
                    <NavLink to={"./photo-editor"} activeClassName="activeRoute">
                        Fotos
                    </NavLink>
                )}
                <NavLink to={"./login"} activeClassName="activeRoute">
                    Login
                </NavLink>
                <NavLink to={"./settings"} activeClassName="activeRoute">
                    Configuración
                </NavLink>
            </div>
        </header>
    );
};

export default Header;
