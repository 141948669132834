import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./PricePlan.scss";

const PricePlan = () => {
    const user = useSelector((state) => state.user);

    return (
        <div className="pricePlan">
            <div className="plan">
                <div className="info">
                    <h3>Gratis</h3>
                    <p>7 dias de prueba con acceso a los reportes.</p>
                </div>
                <div className="priceSection">
                    <div>$0/anual</div>
                    {user.role ? (
                        <div>Ud. ya es usuario.</div>
                    ) : (
                        <Link to="./login">Crear una Cuenta</Link>
                    )}
                </div>
            </div>
            <div className="plan">
                <div className="info">
                    <h3>Miembro</h3>
                    <p>Reportes históricos de equipos y jugadores.</p>
                </div>
                <div className="priceSection">
                    <div>$14.99/anual</div>
                    <Link to="/payment">Suscribirse</Link>
                </div>
            </div>
            {/* <div className="plan">
                <div className="info">
                    <h3>Member-Pro</h3>
                    <p>Consultor avanzado de estadísticas y comparador.</p>
                </div> 
                <div className="priceSection">
                    <div>$25/anual</div>
                    <Link to="">Member-Pro</Link>
                </div>
            </div> */}
        </div>
    );
};

export default PricePlan;
