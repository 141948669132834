import firebase from "firebase/app";
import "firebase/storage";

const getFile = async (route, filename) => {
    const ref = firebase.storage().ref().child(`${route}/${filename}`);
    const file = await ref.getDownloadURL();
    return file;
};

const uploadPlayerImage = (file, setFileUploadPercent) => {
    return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref().child(`players/${file.name}`);

        const uploadTask = storageRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFileUploadPercent(`${progress.toFixed(2)}%`);
            },
            (error) => {
                reject("error uploading");
            },
            async () => {
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(downloadURL);
            }
        );
    });
};

const uploadTeamLogos = (file, setFileUploadPercent) => {
    return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref().child(`teamLogos/${file.name}`);

        const uploadTask = storageRef.put(file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFileUploadPercent(`${progress.toFixed(2)}%`);
            },
            (error) => {
                reject("error uploading");
            },
            async () => {
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(downloadURL);
            }
        );
    });
};

const getStoragePlayersList = async () => {
    const refs = await firebase.storage().ref().child("players").listAll();
    return refs;
}

export { getFile, uploadPlayerImage, uploadTeamLogos, getStoragePlayersList };
