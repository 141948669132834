import { combineReducers } from "redux";

import userReducer from "./userReducer";
import headerToggleReducer from "./headerToggleReducer";
import validationsReducer from "./validationsReducer";
import yearMetricsReducer from "./yearMetricsReducer";

const rootReducers = combineReducers({
    user: userReducer,
    headerToggle: headerToggleReducer,
    validations: validationsReducer,
    yearMetrics: yearMetricsReducer,
});

export default rootReducers;
