import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { hideHeader } from "../../redux/userActions";
import { recoverUserPassword } from "../../services/auth";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import FormInput from "../../components/FormInput";

import "./RecoverPassword.scss";

const RecoverPassword = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    const handleRecovery = async () => {
        if (email) {
            const res = await recoverUserPassword(email);
            console.log(res);
            setMessage(res);
        } else {
            setMessage("Debe agregar una dirección de correo.");
        }
    };

    return (
        <div className="recoverPassword">
            <HeaderToggle />
            <Overlay />
            <Header />
            <div className="recoveryForm">
                {" "}
                <div>Escriba su dirección de correo electronico:</div>
                <FormInput
                    placeholder="Email"
                    value={email}
                    type="email"
                    onChange={(value) => setEmail(value)}
                />
                <button onClick={handleRecovery}>Recuperar</button>
                <div>{message}</div>
            </div>
        </div>
    );
};

export default RecoverPassword;
