import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getItem } from "../../services/databaseRead";
import {
    setYearValidation,
    setPlayersValidation,
    setYearMetrics,
} from "../../redux/userActions";

import { login } from "../../services/auth";

import FormInput from "../FormInput";

import "./LoginForm.scss";

const LoginForm = ({ history }) => {
    const dispatch = useDispatch();

    //const dispatch = useDispatch();
    const [loginData, setLoginData] = useState({ email: "", password: "" });
    const [message, setMessage] = useState("");
    const user = useSelector((state) => state.user);

    const getYearsVal = async () => {
        const result = await getItem("yearValidation", "years");
        if (result) {
            dispatch(setYearValidation(JSON.parse(result.years)));
        }
    };
    const getPlayersVal = async () => {
        const result = await getItem("playersValidation", "players");
        if (result) {
            dispatch(setPlayersValidation(JSON.parse(result.players)));
        }
    };
    const getYearMetrics = async () => {
        const result = await getItem("yearMetrics", "metrics");
        if (result) {
            dispatch(setYearMetrics(JSON.parse(result.metrics)));
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setMessage("");

        const { email, password } = loginData;

        if (!email || !password) {
            setMessage("Por favor, rellene todos los campos.");
        } else {
            const result = await login(email, password);
            if (result.message) {
                setMessage(result.message);
            } else {
                //if login is successful
                getYearsVal();
                getPlayersVal();
                getYearMetrics();
                history.push("./home");
            }
        }
    };

    return (
        <form onSubmit={handleLogin} className="loginForm">
            <h3>Iniciar Sesión</h3>
            <FormInput
                placeholder="Email"
                value={loginData.email}
                type="email"
                onChange={(value) => setLoginData({ ...loginData, email: value })}
            />
            <FormInput
                placeholder="Contraseña"
                value={loginData.password}
                type="password"
                onChange={(value) => setLoginData({ ...loginData, password: value })}
            />
            <button type="submit">Ingresar</button>
            <span className="message">{message}</span>
            {user.role === "pending" && (
                <span className="message">Email pendiente de validación.</span>
            )}
        </form>
    );
};

export default LoginForm;
