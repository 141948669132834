import React, { useState } from "react";

import "./Dropdown.scss";

const Dropdown = ({ options, field, getQueryData, defaultOption }) => {
    const [selection, setSelection] = useState(
        Object.keys(options).sort((a, b) => b - a)[defaultOption]
    );

    const handleOptionChange = (event) => {
        const { value } = event.target;
        if (value) {
            setSelection(value);
            const returnObj = {};
            returnObj[field] = options[value]; //field is custom prop from parent
            getQueryData(returnObj); //sends object to parent.
        }
    };

    return (
        <select className="dropdown" value={selection} onChange={handleOptionChange}>
            {Object.keys(options)
                .sort((a, b) => b - a)
                .map((key) => {
                    return <option key={key}>{key}</option>;
                })}
        </select>
    );
};

export default Dropdown;
