import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import querystring from "query-string";

import { hideHeader } from "../../redux/userActions";

import {
    phases,
    teams,
    batStats,
    pitchStats,
    ascPitchMetrics,
} from "../../helpers/validation";
import {
    teamsSeparatePlayers,
    sortArrayBy,
    addBatCalculations,
    addPitchCalculations,
    totalize,
} from "../../helpers/structureData";

import { getItem } from "../../services/databaseRead";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";
import Dropdown from "../../components/Dropdown";
import Notice from "../../components/Notice";
import TeamHero from "../../components/TeamHero";
import TeamBatters from "../../components/Tables/TeamBatters";
import TeamPitchers from "../../components/Tables/TeamPitchers";

import "./Teams.scss";

const Teams = ({ history }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const years = useSelector((state) => state.validations.years);
    const yearMetrics = useSelector((state) => state.yearMetrics.metrics);

    const keys = querystring.parse(history.location.search);

    const [queryData, setQueryData] = useState({
        year: Object.values(years)[Object.values(years).length - 1],
        phase: "SR",
        team: "AC",
    });

    const [batters, setBatters] = useState("");
    const [pitchers, setPitchers] = useState("");
    const [globalData, setGlobalData] = useState("");
    const [battersOrderedBy, setBattersOrderedBy] = useState("");
    const [pitchersOrderedBy, setPitchersOrderedBy] = useState("");
    const [notice, setNotice] = useState("");

    useEffect(() => {
        //hides header (visible on mobile) when arriving at new route.
        dispatch(hideHeader());
    }, [dispatch]);

    useEffect(() => {
        //sets the player based on url params.
        const getTeam = async (year, phase, team) => {
            const results = await getItem("allStats", `${year}${phase}${team}`);
            const globalInfo = await getItem("globalData", year);
            if (results && globalInfo) {
                const players = teamsSeparatePlayers(results);
                setGlobalData(globalInfo[`${year}${phase}${team}`]);
                if (players.bat.length > 0) {
                    const modBatters = players.bat.map((player) => {
                        return addBatCalculations(player, yearMetrics[year]);
                    });
                    setBatters(modBatters);
                }
                if (players.bat.length === 0) {
                    setBatters([]);
                }
                if (players.pitch.length > 0) {
                    const modPitchers = players.pitch.map((player) => {
                        return addPitchCalculations(player, yearMetrics[year]);
                    });
                    setPitchers(modPitchers);
                }
                if (players.pitch.length === 0) {
                    setPitchers([]);
                }
            } else {
                setNotice("No hay resultados.");
            }
        }; //if there is a key on the url and exists on validation.
        if (keys.year && keys.phase && keys.team) {
            getTeam(keys.year, keys.phase, keys.team);
        }
    }, [keys.year, keys.phase, keys.team, yearMetrics]);

    const getQueryData = (obj) => {
        //prop to get data from the filters form (child)
        setQueryData({ ...queryData, ...obj });
    };

    const reorder = (field, origin) => {
        //triggered by clicking on the table header (child)
        if (origin === "batter") {
            const reordered = sortArrayBy([...batters], field);
            setBattersOrderedBy(field);
            setBatters(reordered);
        } else {
            const reordered = sortArrayBy(
                [...pitchers],
                field,
                ascPitchMetrics.includes(field) ? "ascending" : "descending"
            );
            setPitchersOrderedBy(field);
            setPitchers(reordered);
        }
    };

    const handleTeamSearch = async (event) => {
        event.preventDefault();
        setNotice("");
        const { year, phase, team } = queryData;
        const validYear =
            year === undefined
                ? Object.values(years)[Object.values(years).length - 1]
                : year;
        if (validYear && phase && team) {
            history.push(`teams?year=${validYear}&phase=${phase}&team=${team}`);
        }
    };

    return (
        <div className="teams">
            <HeaderToggle />
            <Overlay />
            <Header />
            {user.role === "member" ||
            user.role === "member-pro" ||
            user.role === "photo-editor" ||
            user.role === "admin" ? (
                <>
                    <form onSubmit={handleTeamSearch}>
                        <div>
                            <div>Torneo</div>
                            <Dropdown
                                options={years}
                                field="year"
                                getQueryData={getQueryData}
                                defaultOption={Object.keys(years).length - 1}
                            />
                        </div>
                        <div>
                            <div>Etapa</div>
                            <Dropdown
                                options={phases}
                                field="phase"
                                getQueryData={getQueryData}
                            />
                        </div>
                        <div>
                            <div>Equipo</div>
                            <Dropdown
                                options={teams}
                                field="team"
                                getQueryData={getQueryData}
                            />
                        </div>
                        <button type="submit">Buscar</button>
                    </form>
                    <div className="content">
                        {!(batters || pitchers) ? (
                            <div className="noResults">
                                No hay resultados que mostrar.
                            </div>
                        ) : (
                            <>
                                {notice && <div className="noResults">{notice}</div>}
                                <TeamHero globalData={globalData} />
                                {batters.length > 0 ? (
                                    <TeamBatters
                                        batters={batters}
                                        reorder={reorder}
                                        totals={{
                                            ...addBatCalculations(
                                                totalize(batters, Object.keys(batStats), [
                                                    "age",
                                                ]),
                                                yearMetrics[batters["year"]]
                                            ),
                                            playerDesc: "Totales",
                                        }}
                                        orderedBy={battersOrderedBy}
                                    />
                                ) : null}
                                {pitchers.length > 0 ? (
                                    <TeamPitchers
                                        pitchers={pitchers}
                                        reorder={reorder}
                                        totals={{
                                            ...addPitchCalculations(
                                                totalize(
                                                    pitchers,
                                                    Object.keys(pitchStats),
                                                    ["age"]
                                                ),
                                                yearMetrics[pitchers["year"]]
                                            ),
                                            playerDesc: "Totales",
                                        }}
                                        orderedBy={pitchersOrderedBy}
                                    />
                                ) : null}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <Notice requiredMemebership="suscriptor" />
            )}
        </div>
    );
};

export default Teams;
