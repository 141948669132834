import React, { useState } from "react";
import { getItem } from "../../services/databaseRead";
import { addItemWithId } from "../../services/databaseWrite";
import { getFile, uploadPlayerImage, uploadTeamLogos } from "../../services/storage";

import { validateImageExtension } from "../../helpers/structureData";

import FormInput from "../FormInput";

import "./UpdatePanel.scss";
import defaultPic from "../../images/playerthumbnail.png";

const UpdatePanel = () => {
    const [singlePlayerID, setSinglePlayerID] = useState("");
    const [playerResult, setPlayerResult] = useState({ id: "" });
    const [playerPic, setPlayerPic] = useState("");
    const [message, setMessage] = useState("");
    const [teamLogoMessage, setTeamLogoMessage] = useState("");
    const [fileUploadPercent, setFileUploadPercent] = useState(0);
    const [teamLogofileUploadPercent, setTeamLogofileUploadPercent] = useState(0);

    const getPlayerPic = async (key) => {
        try {
            const result = await getFile("players", `thumb_${key}.jpeg`);
            if (result) {
                setPlayerPic(result);
            }
        } catch (e) {
            setPlayerPic(defaultPic);
        }
    };

    const handleUploadImage = async (event) => {
        const file = event.target.files[0];
        if (file && validateImageExtension(file.name.toLowerCase())) {
            Object.defineProperty(file, "name", {
                writable: true,
                value: `thumb_${playerResult.id}.jpeg`,
            });
            const downloadUrl = await uploadPlayerImage(file, setFileUploadPercent);
            console.log("downloadURL", downloadUrl);
            if (downloadUrl) {
                setFileUploadPercent(100);
                setMessage(`Uploaded image thumb_${playerResult.id}.jpeg`);
            }
        }
    };

    const handleUploadTeamLogo = async (event) => {
        const file = event.target.files[0];
        if (file && validateImageExtension(file.name.toLowerCase())) {
            const downloadUrl = await uploadTeamLogos(file, setTeamLogofileUploadPercent);
            console.log("downloadURL", downloadUrl);
            if (downloadUrl) {
                setFileUploadPercent(100);
                setTeamLogoMessage(`Uploaded to Team Logos`);
            }
        }
    };

    const handleGetOnePlayer = async (event) => {
        event.preventDefault();
        setMessage("");
        if (singlePlayerID) {
            try {
                const result = await getItem("players", singlePlayerID);
                if (result) {
                    console.log(result);
                    setPlayerResult(result);
                    getPlayerPic(result.id);
                } else {
                    setMessage("No results found.");
                }
            } catch (e) {
                setPlayerResult({ id: "" });
                setMessage("Query not performed. Error");
            }
        } else {
            setMessage("Please enter an ID");
        }
    };

    const handleUpdatePlayer = async (event) => {
        event.preventDefault();
        const result = await addItemWithId("players", playerResult, playerResult.id);
        if (result) {
            setMessage(`Player ${playerResult.id} ${playerResult.name} updated!`);
            setPlayerResult({ id: "" });
        }
    };

    return (
        <div className="playerPanel">
            <div className="playerPhotoForm">
                <h4>Team Logos</h4>
                <input type="file" onChange={handleUploadTeamLogo} />
                <span className="displayPercent">{teamLogofileUploadPercent}</span>
                <div className="message">{teamLogoMessage}</div>
            </div>
            <form className="getPlayer" onSubmit={handleGetOnePlayer}>
                <h4>Get a player with ID</h4>
                <FormInput
                    placeholder="ID or Key"
                    value={singlePlayerID}
                    onChange={(value) => setSinglePlayerID(value)}
                />
                <button type="submit">Get Player</button>
                <div className="message">{message}</div>
            </form>
            {playerResult.id ? (
                <div className="results">
                    <div>
                        <h4>Player Info</h4>
                        <form className="playerInfoForm" onSubmit={handleUpdatePlayer}>
                            <label>
                                name:
                                <FormInput
                                    placeholder="name"
                                    value={playerResult.name}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, name: value })
                                    }
                                />
                            </label>
                            <label>
                                number:
                                <FormInput
                                    placeholder="number"
                                    value={playerResult.number}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            number: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                alias:
                                <FormInput
                                    placeholder="alias"
                                    value={playerResult.alias}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, alias: value })
                                    }
                                />
                            </label>
                            <label>
                                bats:
                                <FormInput
                                    placeholder="bats"
                                    value={playerResult.bats}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, bats: value })
                                    }
                                />
                            </label>
                            <label>
                                bornLocation:
                                <FormInput
                                    placeholder="bornLocation"
                                    value={playerResult.bornLocation}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            bornLocation: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                bornday:
                                <FormInput
                                    placeholder="bornday"
                                    value={playerResult.bornday}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            bornday: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                comment:
                                <FormInput
                                    placeholder="comment"
                                    value={playerResult.comment}
                                    maxLength={500}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            comment: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                country:
                                <FormInput
                                    placeholder="country"
                                    value={playerResult.country}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            country: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                debut:
                                <FormInput
                                    placeholder="debut"
                                    value={playerResult.debut}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, debut: value })
                                    }
                                />
                            </label>
                            <label>
                                died:
                                <FormInput
                                    placeholder="died"
                                    value={playerResult.died}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, died: value })
                                    }
                                />
                            </label>
                            <label>
                                height:
                                <FormInput
                                    placeholder="height"
                                    value={playerResult.height}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            height: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                natimp:
                                <FormInput
                                    placeholder="natimp"
                                    value={playerResult.natimp}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            natimp: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                pos:
                                <FormInput
                                    placeholder="pos"
                                    value={playerResult.pos}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, pos: value })
                                    }
                                />
                            </label>
                            <label>
                                throw:
                                <FormInput
                                    placeholder="throw"
                                    value={playerResult.throw}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, throw: value })
                                    }
                                />
                            </label>
                            <label>
                                url:
                                <FormInput
                                    placeholder="url"
                                    value={playerResult.url}
                                    onChange={(value) =>
                                        setPlayerResult({ ...playerResult, url: value })
                                    }
                                />
                            </label>
                            <label>
                                weight:
                                <FormInput
                                    placeholder="weight"
                                    value={playerResult.weight}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            weight: value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                Transacciones:
                                <FormInput
                                    placeholder="Transactions"
                                    value={playerResult.Transactions}
                                    onChange={(value) =>
                                        setPlayerResult({
                                            ...playerResult,
                                            Transactions: value,
                                        })
                                    }
                                    maxLength={200}
                                />
                            </label>
                            <button type="submit" className="updatePlayerButton">
                                Update Player
                            </button>
                        </form>
                    </div>
                    <div className="playerPhotoForm">
                        <h4>Player Photo</h4>
                        <img alt="playerImage" src={playerPic} />
                        <input type="file" onChange={handleUploadImage} />
                        <span className="displayPercent">{fileUploadPercent}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default UpdatePanel;
