import React from "react";

import "./TableCell.scss";

const TableCell = ({ value, x, amTotal, header, orderedBy, className = "" }) => {
    return (
        <td
            className={`tableCell ${x} ${amTotal ? `total ${className}` : ""} ${
                header === orderedBy ? "highlight" : ""
            }`}
        >
            {value}
        </td>
    );
};

export default TableCell;
