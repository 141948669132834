import React, { useState } from "react";

import Header from "../../components/Header";
import HeaderToggle from "../../components/HeaderToggle";
import Overlay from "../../components/Overlay/Overlay";

import "./PayLinks.scss";

const External = ({ url }) => {
    window.location.href = url;
    return null;
};

const PayLinks = () => {
    const options = {
        paypal: "https://www.paypal.me/winterballdata",
        card: "https://buy.stripe.com/9AQbMd39R7Ygdhu149",
    };

    const [url, setUrl] = useState(false);

    return (
        <>
            <HeaderToggle />
            <Overlay />
            <Header />
            <div className="payLinks">
                <h3>Opciones de Pago:</h3>
                <button
                    onClick={() => {
                        setUrl(options["paypal"]);
                    }}
                >
                    PayPal
                </button>
                <button
                    onClick={() => {
                        setUrl(options["card"]);
                    }}
                >
                    Tarjeta de Crédito
                </button>

                {url && <External url={url} />}
            </div>
        </>
    );
};

export default PayLinks;
