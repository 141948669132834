//functions to calculate baseball metrics.

//basic helpers functions:

const n = (string) => {
    return parseFloat(string);
};

const c = (value, onNaN) => {
    // c for check, returns a provided value in the case the calculation yields a NaN
    return isNaN(value) || value === "Infinity" ? onNaN : value;
};

const d = (value, onNaN) => {
    // c for check, returns a provided value in the case the calculation yields a NaN
    return isNaN(value) ? onNaN : value === "Infinity" ? "Inf" : value;
};

const replaceInfinity = (value) => {
    return value === "Infinity" ? "Inf" : value;
};

const format = (number, onNaN, decimals) => {
    const toText = number.toFixed(decimals);
    if (toText[0] === "0") {
        return c(` ${toText.substr(1, toText.length)}`, onNaN);
    } else {
        return c(toText, onNaN);
    }
};

//functions for batters (hitters):

const avg = (h, ab) => {
    return format(n(h) / n(ab), ".000", 3);
};

const obp = (h, bb, hbp, ab, sf) => {
    return format((n(h) + n(bb) + n(hbp)) / (n(ab) + n(bb) + n(hbp) + n(sf)), ".000", 3);
};

const slg = (tb, ab) => {
    return format(n(tb) / n(ab), ".000", 3);
};

const ops = (obp, slg) => {
    return format(n(obp) + n(slg), ".000", 3);
};

//functions for pitchers...

const pct = (wp, decp) => {
    return format(n(wp) / n(decp), ".000", 3);
};

const era = (erp, ipp) => {
    return d((9 * (n(erp) / n(ipp))).toFixed(2), "0.00");
};

const whip = (hp, bbp, ipp) => {
    return d(((n(hp) + n(bbp)) / n(ipp)).toFixed(2), "0.00");
};

//functions from globalData...

const yearOps = (oba1, oba2, tb, ab) => {
    return c((n(oba1) / n(oba2) + n(tb) / n(ab)).toFixed(3), "0");
};

const opsPlus = (ops, yearOps) => {
    return c(((n(ops) / n(yearOps)) * 100).toFixed(0), "0.000");
};

const eraPlus = (eraS, eraP) => {
    return replaceInfinity(c((n(eraS) / n(eraP)) * 100, 0).toFixed());
};

const fip = (hrp, bbp, hbpp, sop, ipp, fipconst) => {
    const e = 13 * n(hrp);
    const f = 3 * (n(bbp) + n(hbpp));
    const g = 2 * n(sop);
    const h = n(ipp);
    return c(((e + f - g) / h + n(fipconst)).toFixed(2), "0.00");
};

export { avg, obp, slg, ops, yearOps, opsPlus, pct, era, whip, fip, eraPlus };
