import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { registerAuthObserver } from "./services/auth";
import { getItem } from "./services/databaseRead";
import { addItemWithId } from "./services/databaseWrite";
import {
    setUser,
    setYearValidation,
    setPlayersValidation,
    setYearMetrics,
} from "./redux/userActions";
import { trialPeriod, year } from "./helpers/variables";

import "./App.css";

import Login from "./pages/Login";
import Teams from "./pages/Teams";
import Players from "./pages/Players";
import SignUp from "./pages/SignUp";
import RecoverPassword from "./pages/RecoverPassword";
import Settings from "./pages/Settings";
import Manage from "./pages/Manage";
import PayLinks from "./pages/PayLinks";
import Home from "./pages/Home";
import PhotoEditor from "./pages/PhotoEditor";

let cancelObserver;

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (cancelObserver) cancelObserver();

        cancelObserver = registerAuthObserver(async (user) => {
            if (user) {
                if (user.emailVerified) {
                    const profile = await getItem("users", user.uid);
                    if (!profile) {
                        const newProfile = {
                            email: user.email,
                            role: "member",
                            createdOn: +new Date(),
                            trialPeriod: true,
                            pay_date: 1577876400000,
                        };
                        const result = await addItemWithId("users", newProfile, user.uid);
                        result && dispatch(setUser(newProfile));
                    } else {
                        dispatch(setUser(profile));
                        if (
                            profile.role === "member" &&
                            profile.createdOn + trialPeriod < Date.now() &&
                            profile.trialPeriod === true
                        ) {
                            const updatedProfile = {
                                ...profile,
                                role: "standard",
                                trialPeriod: false,
                            };
                            const result = await addItemWithId(
                                "users",
                                updatedProfile,
                                user.uid
                            );
                            result && dispatch(setUser(updatedProfile));
                        }
                        if (
                            profile.role === "member" &&
                            profile.pay_date + year < Date.now() &&
                            profile.trialPeriod === false
                        ) {
                            const updatedProfile = { ...profile, role: "standard" };
                            const result = await addItemWithId(
                                "users",
                                updatedProfile,
                                user.uid
                            );
                            result && dispatch(setUser(updatedProfile));
                        }
                    }
                } else {
                    dispatch(setUser({ role: "pending" }));
                }
            } else {
            }
        });

        return () => {
            cancelObserver();
        };
    }, [dispatch]);

    useEffect(() => {
        //initiall calls for relevant data the app needs for several reports and validations
        const getYearsVal = async () => {
            const result = await getItem("yearValidation", "years");
            if (result) {
                dispatch(setYearValidation(JSON.parse(result.years)));
            }
        };
        const getPlayersVal = async () => {
            const result = await getItem("playersValidation", "players");
            if (result) {
                dispatch(setPlayersValidation(JSON.parse(result.players)));
            }
        };
        const getYearMetrics = async () => {
            const result = await getItem("yearMetrics", "metrics");
            if (result) {
                dispatch(setYearMetrics(JSON.parse(result.metrics)));
            }
        };
        getYearsVal();
        getPlayersVal();
        getYearMetrics();
    }, [dispatch]);

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/home" component={Home}></Route>
                    <Route path="/teams" component={Teams}></Route>
                    <Route path="/players" component={Players}></Route>
                    <Route path="/login" component={Login}></Route>
                    <Route path="/signup" component={SignUp}></Route>
                    <Route path="/recoverPassword" component={RecoverPassword}></Route>
                    <Route path="/settings" component={Settings}></Route>
                    <Route path="/manage" component={Manage}></Route>
                    <Route path="/photo-editor" component={PhotoEditor}></Route>
                    <Route path="/payment" component={PayLinks}></Route>
                    <Route path="/" component={Home}></Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
