import firebase from "firebase/app";
import "firebase/firestore";

let db;
function getDbInstance() {
    if (!db || db._isTerminated) {
        db = firebase.firestore();
    }
    return db;
}

async function addItemWithId(collection, item, id) {
    console.log("adding item with id");
    const db = getDbInstance();
    const result = await db.collection(collection).doc(id).set(item, { merge: true });
    return !result;
}

async function deleteItem(collection, itemId) {
    console.log("deleting item");
    const db = getDbInstance();
    const result = await db.collection(collection).doc(itemId).delete();
    return !result;
}

async function setStats(yearDoc, phase, teamDoc, playersObject) {
    const db = getDbInstance();
    const result = await db
        .collection("allStats")
        .doc(`${yearDoc}${phase}${teamDoc}`)
        .set(playersObject, { merge: true });
    console.log("wrote to allStats");
    return result;
}

async function setPlayerStats(playerCode, playerObject) {
    const db = getDbInstance();
    const result = await db
        .collection("players")
        .doc(playerCode)
        .set({ stats: playerObject }, { merge: true });
    console.log("wrote to player - stats");
    return result;
}

async function setPlayerPrizes(playerCode, prizesObject) {
    const db = getDbInstance();
    const result = await db
        .collection("players")
        .doc(playerCode)
        .set({ prizes: prizesObject }, { merge: true });
    console.log("wrote to player - prizes");
    return result;
}

async function setGlobalStats(yearDoc, object) {
    const db = getDbInstance();
    const result = await db
        .collection("globalData")
        .doc(yearDoc)
        .set(object, { merge: true });
    console.log("wrote global stat and info");
    return result;
}

const deleteFromCollection = async (collection) => {
    const db = getDbInstance();
    const collectionRef = db.collection(collection);
    const results = [];
    await collectionRef.get().then(function (querySnapshot) {
        querySnapshot.forEach((doc) => {
            results.push(doc.id);
        });
    });
    // return results;
    if (results) {
        console.log("Starting to delete...");
        results.forEach(async (entry) => {
            await collectionRef
                .doc(entry)
                .delete()
                .then(function () {
                    console.log("doc deleted");
                });
        });
        return "completed deletion";
    }
};

const deletePlayerFromAllStats = async (allStatsDoc, fieldToDelete) => {
    const db = getDbInstance();
    const docRef = db.collection("allStats").doc(allStatsDoc);
    const docToRemove = {};
    docToRemove[fieldToDelete] = firebase.firestore.FieldValue.delete();
    await docRef.update(docToRemove);
};

export {
    addItemWithId,
    deleteItem,
    setStats,
    setPlayerStats,
    setPlayerPrizes,
    setGlobalStats,
    deleteFromCollection,
    deletePlayerFromAllStats,
};
