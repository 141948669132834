const initialState = { metrics: "" };

function yearMetricsReducer(state = initialState, action) {
    switch (action.type) {
        case "setYearMetrics":
            return { ...state, metrics: action.payload };
        default:
            return state;
    }
}

export default yearMetricsReducer;
